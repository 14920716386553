import React, { useState, useEffect } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import firebase from 'config/firebase';
import 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const Perfil: React.FC = () => {
  const auth = getAuth(firebase);
  const [user] = useAuthState(auth);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [nome, setNome] = useState<string>('');
  const [nomeUsuario, setNomeUsuario] = useState<string>('');
  const [telefone, setTelefone] = useState<string>('');
  const [sexo, setSexo] = useState<string>('');
  const [aniversario, setAniversario] = useState<string>('');
  const [dataCriacao, setDataCriacao] = useState<string>('');
  const [image, setImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [msgTipo, setMsgTipo] = useState<string | undefined>();
  const [msg, setMsg] = useState<string | undefined>();
  const [urlImagens, setUrlImagens] = useState<string | undefined>(undefined);
  const [urlImagemNova, setUrlImagemNova] = useState<string | undefined>(
    undefined
  );
  const [fotoNova, setFotoNova] = useState<File | null>(null);

  const defaultProfileUrl = 'URL_DA_IMAGEM_PADRÃO';
  const usuarioEmail = useSelector(
    (state: RootState) => state.usuario.usuarioEmail
  );
  const storage = getStorage();
  useEffect(() => {
    const fetchUrls = async () => {
      try {
        const urlFotoPerfil = await getDownloadURL(
          ref(storage, `Usuários/${usuarioEmail}/FotoPerfil/${'FotoPerfil'}`)
        );
        setUrlImagens(urlFotoPerfil);
      } catch (error) {
        console.error('Erro ao obter URL da imagem do perfil:', error);
      }

      try {
        const urlImagemNova = await getDownloadURL(
          ref(storage, `Usuários/${'user defalt.png'}`)
        );
        setUrlImagemNova(urlImagemNova);
      } catch (error) {
        console.error('Erro ao obter URL da imagem padrão:', error);
      }
    };

    fetchUrls();
  }, [usuarioEmail, storage]);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          const userDoc = await firebase
            .firestore()
            .collection('Usuários')
            .doc(user.email!)
            .get();
          if (userDoc.exists) {
            const data = userDoc.data();
            setNome(data?.InfoPessoais.Nome || '');
            setNomeUsuario(data?.InfoPessoais.NomeUsuario || '');
            setTelefone(data?.InfoPessoais.Telefone || '');
            setSexo(data?.InfoPessoais.Sexo || '');
            setAniversario(data?.InfoPessoais.Aniversário || '');

            const dataCriacaoFormatada =
              data?.DataCriação.toDate().toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              }) || '';

            setDataCriacao(dataCriacaoFormatada);
            setPreviewUrl(
              data?.InfoPessoais.FotoPerfilUrl || defaultProfileUrl
            );
          }
        } catch (error) {
          console.error('Erro ao buscar dados do usuário:', error);
          setPreviewUrl(defaultProfileUrl);
        } finally {
          setPageLoading(false); // Página carregada
        }
      };
      fetchData();
    }
  }, [user, defaultProfileUrl]);

  // Função para garantir que apenas números sejam digitados no campo de telefone
  const handleTelefoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere não numérico
    setTelefone(value);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      resizeImage(file); // Redimensiona a imagem
    }
  };

  const resizeImage = async (file: File) => {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(file);

    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const maxWidth = 1000; // largura máxima
      const maxHeight = 1000; // altura máxima

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      context?.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        async blob => {
          if (blob) {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            setFotoNova(resizedFile); // Defina a imagem redimensionada como a nova imagem
          }
        },
        'image/jpeg',
        0.9
      ); // Qualidade da imagem (0.9 = 90%)
    };
  };

  const handleSaveProfile = async () => {
    setLoading(true);
    try {
      await firebase
        .firestore()
        .collection('Usuários')
        .doc(user?.email || '')
        .update({
          'InfoPessoais.Nome': nome,
          'InfoPessoais.Telefone': telefone,
          'InfoPessoais.Sexo': sexo,
          'InfoPessoais.Aniversário': aniversario
        });
      setMsgTipo('Sucesso');
      setMsg('Perfil atualizado com sucesso!');
      setShowModal(true);
    } catch (error) {
      console.error('Erro ao atualizar o perfil:', error);
      setMsgTipo('Erro');
      setMsg('Erro ao atualizar o perfil. Tente novamente.');
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const cadastrarfoto = () => {
    if (!fotoNova) return;
    setLoading(true);
    const imageRef = ref(
      storage,
      `Usuários/${usuarioEmail}/FotoPerfil/${'FotoPerfil'}`
    );
    uploadBytes(imageRef, fotoNova).then(snapshot => {
      getDownloadURL(snapshot.ref)
        .then(url => {
          setUrlImagens(url);
          firebase
            .firestore()
            .collection('Usuários')
            .doc(user?.email || '')
            .update({
              'InfoPessoais.FotoPerfilUrl': url
            });
          setPreviewUrl(url);
          setMsgTipo('Sucesso');
          setMsg('Foto de perfil atualizada com sucesso!');
          setShowModal(true);
          setLoading(false);
        })
        .catch(error => {
          console.error('Erro ao salvar a foto de perfil:', error);
          setMsgTipo('Erro');
          setMsg('Erro ao atualizar foto de perfil. Tente novamente.');
          setShowModal(true);
          setLoading(false);
        });
    });
  };

  if (pageLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container mt-5 row">
      <div className="col-md-6 border-end border-2">
        <h2>Foto Perfil</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img
            className="border border-3"
            src={urlImagens ? urlImagens : urlImagemNova}
            alt="Foto de perfil"
            style={{ width: '300px', height: '300px' }}
          />
          <Button
            variant="secondary"
            className="mt-2"
            onClick={() => document.getElementById('formFotoPerfil')?.click()}
          >
            Alterar Foto
          </Button>
          <Form.Group controlId="formFotoPerfil" className="d-none">
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Form.Group>
          {image && previewUrl && (
            <div className="mt-2">
              <h6>Pré-visualização:</h6>
              <img
                className="border border-3"
                src={previewUrl}
                alt="Pré-visualização"
                style={{ width: '150px', height: '150px' }}
              />
            </div>
          )}
          <Button
            variant="primary"
            className="mt-2"
            onClick={cadastrarfoto}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Salvar Foto'
            )}
          </Button>
        </div>
      </div>
      <div className="col-md-6">
        <h2>Informações do Meu Perfil</h2>
        <Form>
          <Form.Group controlId="formNome">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              value={nome}
              onChange={e => setNome(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formNomeUsuario" className="mt-3">
            <Form.Label>Nome de Usuário</Form.Label>
            <Form.Control type="text" value={nomeUsuario} readOnly />
          </Form.Group>

          <Form.Group controlId="formTelefone" className="mt-3">
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              type="text"
              value={telefone}
              onChange={handleTelefoneChange}
            />
          </Form.Group>

          <Form.Group controlId="formSexo" className="mt-3">
            <Form.Label>Sexo</Form.Label>
            <Form.Control
              as="select"
              value={sexo}
              onChange={e => setSexo(e.target.value)}
            >
              <option value="" disabled>
                Selecione
              </option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
              <option value="Outro">Outro</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formAniversario" className="mt-3">
            <Form.Label>Aniversário</Form.Label>
            <Form.Control
              type="date"
              value={aniversario}
              onChange={e => setAniversario(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formDataCriacao" className="mt-3">
            <Form.Label>Data de Criação</Form.Label>
            <Form.Control type="text" value={dataCriacao} readOnly />
          </Form.Group>

          <Button
            variant="primary"
            className="mt-4"
            onClick={handleSaveProfile}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Salvar'
            )}
          </Button>
        </Form>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Body className="text-center">
            <AiOutlineInfoCircle
              size={50}
              className={
                msgTipo === 'Sucesso'
                  ? 'text-success my-3'
                  : 'text-primary my-3'
              }
            />
            <h4>{msgTipo === 'Sucesso' ? 'Sucesso' : 'Atenção'}</h4>
            <p>{msg}</p>
            <Button
              variant={msgTipo === 'Sucesso' ? 'success' : 'primary'}
              onClick={() => setShowModal(false)}
              className="mt-3"
            >
              Confirmar
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Perfil;
