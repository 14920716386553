import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, Row, ListGroup, Spinner } from 'react-bootstrap';
import { getFirestore, doc, setDoc, getDoc, collection, getDocs, Timestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Importações adicionadas
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import imageCompression from 'browser-image-compression';
import { User } from 'firebase/auth'

const ArtistasFormulario: React.FC = () => {
    const [nome, setNome] = useState<string>('');
    const [detalhes, setDetalhes] = useState<string>('');
    const [artistas, setArtistas] = useState<string[]>([]);
    const [user, setUser] = useState<User | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [msgTipo, setMsgTipo] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [fotoNova, setFotoNova] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const db = getFirestore();
    const storage = getStorage(); // Inicialização do Storage

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
                setMsgTipo('Erro');
                setText('Você precisa estar autenticado para cadastrar artistas.');
                setShowModal(true);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchArtistas = async () => {
            const artistasSnapshot = await getDocs(collection(db, 'Artistas'));
            const artistasList = artistasSnapshot.docs.map(doc => doc.id);
            setArtistas(artistasList);
        };

        fetchArtistas();
    }, [db]);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            // Compactação da imagem
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };

            try {
                const compressedFile = await imageCompression(file, options);
                setFotoNova(compressedFile);
                const preview = URL.createObjectURL(compressedFile);
                setPreviewUrl(preview);
            } catch (error) {
                console.error('Erro ao compactar a imagem:', error);
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!user) {
            setMsgTipo('Erro');
            setText('Você precisa estar autenticado para cadastrar artistas.');
            setShowModal(true);
            return;
        }

        setCarregando(true);

        const nomePadronizado = nome.toLowerCase();  // Padroniza o nome para minúsculas

        const docRef = doc(db, 'Artistas', nomePadronizado);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setMsgTipo('Erro');
            setText('Este artista já foi cadastrado.');
            setShowModal(true);
            setCarregando(false);
        } else {
            const timestamp = Timestamp.now();
            const historico = [
                {
                    email: user.email,
                    dataHora: timestamp,
                    acao: 'Cadastro'
                }
            ];

            await setDoc(docRef, {
                nome: nomePadronizado,
                detalhes,
                foto: '',  // Adicione aqui a URL da foto após o upload, se houver
                historico
            });

            if (fotoNova) {
                const storageRef = ref(storage, `Artistas/${nomePadronizado}/FotoArtista`);
                await uploadBytes(storageRef, fotoNova);
                const fotoURL = await getDownloadURL(storageRef);

                await setDoc(docRef, {
                    nome: nomePadronizado,
                    detalhes,
                    foto: fotoURL,
                    historico
                }, { merge: true });
            }

            setMsgTipo('sucesso');
            setText('Artista cadastrado com sucesso!');
            setShowModal(true);
            setCarregando(false);

            // Limpar os campos do formulário
            setNome('');
            setDetalhes('');
            setFotoNova(null);
            setPreviewUrl(null);
        }
    };

    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' }
            ],
            ['clean']
        ]
    };

    const formats = [
        'header',
        'font',
        'align',
        'bold',
        'italic',
        'underline',
        'list',
        'bullet',
        'link',
        'image'
    ];

    return (
        <div>
            <h3>Artistas já cadastrados</h3>
            <ListGroup className="mb-3">
                {artistas.length > 0 ? (
                    artistas.map((artista, index) => (
                        <ListGroup.Item key={index}>{artista}</ListGroup.Item>
                    ))
                ) : (
                    <ListGroup.Item>Nenhum artista cadastrado ainda.</ListGroup.Item>
                )}
            </ListGroup>

            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formNomeArtista">
                            <Form.Label>Nome do Artista</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nome do Artista"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formFotoArtista">
                            <Form.Label>Foto do Artista</Form.Label>
                            {previewUrl && (
                                <img
                                    src={previewUrl}
                                    alt="Pré-visualização"
                                    style={{
                                        width: '25%',
                                        height: 'auto',
                                        marginBottom: '10px'
                                    }}
                                />
                            )}
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formDetalhesArtista">
                            <Form.Label>Detalhes do Artista</Form.Label>
                            <ReactQuill
                                value={detalhes}
                                onChange={(value) => setDetalhes(value)}
                                modules={modules}
                                formats={formats}
                                placeholder="Digite aqui os detalhes do artista..."
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" type="submit" disabled={carregando}>
                    {carregando ? <Spinner animation="border" size="sm" /> : 'Cadastrar Artista'}
                </Button>
            </Form>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center">
                        <i
                            className={`bi ${msgTipo === 'sucesso'
                                ? 'bi-check-circle-fill'
                                : 'bi-exclamation-triangle-fill'
                                } text-primary mb-3`}
                            style={{
                                fontSize: '2rem',
                            }}
                        ></i>
                        <h5>Atenção</h5>
                        <p className="text-center">{text}</p>
                        <Button variant="primary" onClick={() => setShowModal(false)}>
                            Confirmar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ArtistasFormulario;
