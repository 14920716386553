import React, { useState, useEffect } from 'react';
import { Stack, Button, Form } from 'react-bootstrap'; // Importei Button e Form do react-bootstrap
import firebase from 'config/firebase';
import Card from '../obras/card';
import { Timestamp } from 'firebase/firestore';

interface CardProps {
  id: string;
  titulo: string;
  data: number;
  foto: string;
  usuario: string;
  hora: string;
  local: string;
  idshows: string;
  estado: string;
  cidade: string;
  regiao: string;
  resumo: string;
  tipo: string;
  detalhes: string;
  criação: string;
  vizualizações: number;
  curtidas: number;
  datastring: string;
  idUsuario: string;
  email: string;
  nome: string;
  telefone: number;
  nomeusuario: string;
  artista: string[];
}

interface Shows {
  id: string;
  titulo: string;
  data: Timestamp;
  foto: string;
  usuario: string;
  hora: string;
  local: string;
  idshows: string;
  resumo: string;
  tipo: string;
  detalhes: string;
  criação: string;
  vizualizações: number;
  datastring: string;
  artista: string[];
}

interface Usuário {
  id: string;
  email: string;
  nome: string;
  nomeusuario: string;
  telefone: number;
}

interface Locais {
  id: string;
  cidade: string;
  estado: string;
  regiao: string;
  endereco: string;
}

const Starter = () => {
  const [pesquisa, setPesquisa] = useState(''); // Inicializa o estado para a pesquisa
  const [cardsData, setCardsData] = useState<CardProps[]>([]);

  useEffect(() => {
    const fetchShowsAndUsers = async () => {
      try {
        const shows: Shows[] = [];
        const usuarios: Usuário[] = [];
        const locais: Locais[] = [];
        const showsResult = await firebase
          .firestore()
          .collection('Shows')
          .where('data', '>=', new Date())
          .get();

        showsResult.docs.forEach(doc => {
          const data = doc.data() as Omit<Shows, 'id'>;
          if (pesquisa === '' || data.titulo.toLowerCase().includes(pesquisa.toLowerCase())) { // Pesquisa por título
            shows.push({
              id: doc.id,
              ...data,
              data: data.data
            });
          }
        });

        const usuariosResult = await firebase
          .firestore()
          .collection('Usuários')
          .get();

        usuariosResult.docs.forEach(doc => {
          const data = doc.data();
          const infoPessoais = data.InfoPessoais || {};
          usuarios.push({
            id: doc.id,
            nome: infoPessoais.Nome || '',
            nomeusuario: infoPessoais.NomeUsuario || '',
            email: infoPessoais.Email || '',
            telefone: infoPessoais.Telefone || ''
          });
        });

        const locaisResult = await firebase
          .firestore()
          .collection('Locais')
          .get();

        locaisResult.docs.forEach(doc => {
          const data = doc.data();
          locais.push({
            id: doc.id,
            cidade: data.cidade || '',
            estado: data.estado || '',
            regiao: data.regiao || '',
            endereco: data.endereco || ''
          });
        });

        const combinedData: CardProps[] = shows.map(show => {
          const usuario = usuarios.find(u => u.id === show.usuario);
          const local = locais.find(l => l.id === show.local);
          return {
            ...show,
            data: show.data.seconds * 1000,
            nome: usuario?.nome || '',
            nomeusuario: usuario?.nomeusuario || '',
            email: usuario?.email || '',
            telefone: usuario?.telefone || 0,
            curtidas: 0,
            estado: local?.estado || '',
            cidade: local?.cidade || '',
            regiao: local?.regiao || '',
            idUsuario: usuario?.id || '',
            artista: show.artista || []
          };
        });

        setCardsData(combinedData);
      } catch (error) {
        console.error('Erro ao carregar os dados:', error);
      }
    };

    fetchShowsAndUsers();
  }, [pesquisa]); // Atualiza a busca sempre que "pesquisa" mudar

  const handleSearch = () => {
    // Aciona a atualização da pesquisa (o valor será monitorado automaticamente)
  };

  return (
    <Stack>
      <div className="row mb-4">
        <div className="col-12">
          <h2 className="mb-3">Eventos</h2>
          <div className="d-flex align-items-center justify-content-end">
            <Form.Control
              type="text"
              placeholder="Buscar evento pelo título"
              value={pesquisa}
              onChange={e => setPesquisa(e.target.value)}
              className="me-2 form-control-sm"
              style={{ width: '200px' }}
            />
            <Button onClick={handleSearch}>Buscar</Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row container-fluid content-header float-end">
            {cardsData.map(item => (
              <Card
                key={item.id}
                id={item.id}
                img={item.foto}
                usuario={item.usuario}
                data={item.data}
                local={item.local}
                titulo={item.titulo}
                idshows={item.idshows}
                estado={item.estado}
                cidade={item.cidade}
                regiao={item.regiao}
                resumo={item.resumo}
                tipo={item.tipo}
                vizualizações={item.vizualizações}
                curtidas={item.curtidas}
                nome={item.nome}
                nomeusuario={item.nomeusuario}
              />
            ))}
          </div>
        </div>
      </div>
    </Stack>

  );
};

export default Starter;
