import React, { useState, useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import starterImg from 'assets/img/spot-illustrations/2.png';
import starterDarkImg from 'assets/img/spot-illustrations/dark_2.png';
import Button from 'components/base/Button';
import { Link } from 'react-router-dom';
import firebase from 'config/firebase';

interface Show {
  id: string;
  titulo: string;
  data: number; // Garantido que seja sempre number
  foto: string;
  usuario: string;
  hora: string;
  local: string;
  idshows: string;
  estado: string;
  tipo: string;
  detalhes: string;
  resumo: string;
  endereço: string;
  criação: string;
  vizualizações: number;
  datastring: string; // Garantido que seja sempre string
}

const Starter = () => {
  const [pesquisa] = useState('');
  //#region Shows Rio de Janeiro
  const [, setShowsRioDeJaneiro] = useState<Show[]>([]);
  useEffect(() => {
    const listashowsriodejaneiro: Show[] = [];
    firebase
      .firestore()
      .collection('Shows')
      .where('data', '>=', new Date())
      .get()
      .then(async resultado => {
        await resultado.docs.forEach(doc => {
          const data = doc.data() as Omit<Show, 'id'>;
          if (data.titulo.indexOf(pesquisa) >= 0) {
            listashowsriodejaneiro.push({
              id: doc.id,
              ...data,
              data: data.data
            });
          }
        });
        setShowsRioDeJaneiro(listashowsriodejaneiro);
      });
  }, [pesquisa]);
  //#endregion

  return (
    <Stack className="flex-center content-min-h">
      <div className="text-center py-9">
        <div className="row">
          <div className="col-md-12"></div>
        </div>
        <img
          src={starterImg}
          width={470}
          className="img-fluid mb-7 d-dark-none"
          alt="starter"
        />
        <img
          src={starterDarkImg}
          width={470}
          className="img-fluid mb-7 d-light-none"
          alt="starter"
        />
        <h1 className="text-800 fw-normal mb-5">Create Something Beautiful.</h1>
        <Button
          as={Link}
          className="btn btn-lg btn-primary"
          to="/documentation/getting-started"
        >
          Getting Started
        </Button>
      </div>
    </Stack>
  );
};

export default Starter;
