import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'config/firebase';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

/** Editor de Texto */
import JoditEditor from 'jodit-react';

/** Importação do Tema */
import { Stack } from 'react-bootstrap';
import NavBarsObrasDetalhes from 'components/navbars/NavBarsObrasDetalhes';

/** Moment */
import moment from 'moment';
import 'moment/locale/pt-br';

interface InformacoesBasicas {
  TituloReduzido: string;
  TituloCompleto: string;
  InformaçõesAdicionais: string;
  PrincipaisServiços: string;
  Status: string;
  criacao: string;
}

const InfoBasicasObras = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [, setInformacoesBasicas] = useState<InformacoesBasicas | null>(null);
  const [TituloReduzido, setTituloReduzido] = useState<string>('');
  const [criaçãoobra, setCriaçãoobra] = useState<string>('');
  const [TituloCompleto, setTituloCompleto] = useState<string>('');
  const [Status, setStatus] = useState<string>('');
  const [PrincipaisServiços, setPrincipaisServiços] = useState<string>('');
  const [InformaçõesAdicionais, setInformaçõesAdicionais] =
    useState<string>('');
  const { id } = useParams();
  const editor = useRef(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .get()
      .then(resultado => {
        const data = resultado.data();
        if (data) {
          const infosBasicas = data.InformaçõesBásicas as InformacoesBasicas; // Assegura que o dado é do tipo InformacoesBasicas
          setInformacoesBasicas(infosBasicas);
          setTituloReduzido(infosBasicas.TituloReduzido);
          setTituloCompleto(infosBasicas.TituloCompleto);
          setCriaçãoobra(data.criacao);
          setInformaçõesAdicionais(infosBasicas.InformaçõesAdicionais);
          setPrincipaisServiços(infosBasicas.PrincipaisServiços);
          setStatus(infosBasicas.Status);
        }
      });
  }, []);

  //#region Informações Básicas

  /** Editar Informações Básicas */
  function EditarInformaçõesBásicas() {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .update({
        InformaçõesBásicas: {
          TituloReduzido: TituloReduzido,
          TituloCompleto: TituloCompleto,
          Status: Status,
          PrincipaisServiços: PrincipaisServiços,
          InformaçõesAdicionais: InformaçõesAdicionais
        }
      })
      .then(() => {
        firebase
          .firestore()
          .collection('Obras')
          .doc(id)
          .get()
          .then(resultado => {
            const data = resultado.data();
            if (data) {
              const infosBasicas =
                data.InformaçõesBásicas as InformacoesBasicas;
              setInformacoesBasicas(infosBasicas);
              setTituloReduzido(infosBasicas.TituloReduzido);
              setTituloCompleto(infosBasicas.TituloCompleto);
              setInformaçõesAdicionais(infosBasicas.InformaçõesAdicionais);
              setPrincipaisServiços(infosBasicas.PrincipaisServiços);
              setStatus(infosBasicas.Status);
            }
          });
      });
  }

  //#endregion

  return (
    <Stack className="content-min-h">
      <NavBarsObrasDetalhes />
      <div className="container-fluid">
        <section className="col-md-12 text-center">
          <div className="col-md-1"></div>
          <div className="col-md-11">
            <h3 className="text-danger mb-3">{TituloReduzido}</h3>
            <h3>Informações Básicas</h3>
          </div>
        </section>
      </div>
      <div className="col-md-12">
        <p>
          <span className="font-weight-bold text-primary">
            Título Reduzido:{' '}
          </span>
          {TituloReduzido}
        </p>
      </div>
      <div className="col-md-12">
        <p>
          <span className="font-weight-bold text-primary">
            Data de criação:{' '}
          </span>
          {moment(criaçãoobra).format('L')}
        </p>
      </div>
      <div className="col-md-12">
        <p>
          <span className="font-weight-bold text-primary">
            Título Completo:{' '}
          </span>
          {TituloCompleto}
        </p>
      </div>
      <div className="col-md-12">
        <p>
          <span className="font-weight-bold text-primary">Status: </span>
          {Status}
        </p>
      </div>
      <div className="col-md-12">
        <p>
          <span className="font-weight-bold text-primary">
            Principais Serviços:
          </span>
          <p dangerouslySetInnerHTML={{ __html: PrincipaisServiços }} />
        </p>
      </div>
      <div className="col-md-12">
        <p>
          <span className="font-weight-bold text-primary">
            Informações Adicionais:
          </span>
          <p dangerouslySetInnerHTML={{ __html: InformaçõesAdicionais }} />
        </p>
      </div>

      <div className="d-flex justify-content-end">
        <button className="btn btn-outline-primary" onClick={handleShow}>
          Editar
        </button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Editar Informações Básicas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">
              Título Reduzido
            </label>
            <input
              className="form-control form-control"
              id="sizingInput"
              type="text"
              value={TituloReduzido}
              onChange={e => setTituloReduzido(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">
              Título Completo
            </label>
            <input
              className="form-control form-control"
              id="sizingInput"
              type="text"
              value={TituloCompleto}
              onChange={e => setTituloCompleto(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="datepicker">
              Data de Criação
            </label>
            <input
              className="form-control datetimepicker flatpickr-input"
              id="datepicker"
              type="text"
              placeholder="dd/mm/yyyy"
              data-options='{"disableMobile":true,"dateFormat":"d/m/Y"}'
              readOnly={true}
              value={moment(criaçãoobra).format('L')}
              onChange={e => setCriaçãoobra(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="exampleFormControlInput1">
              Status
            </label>
            <Form.Select
              aria-label="Default select example"
              onChange={e => setStatus(e.target.value)}
              id="inputGroupSelect01"
              value={Status && Status}
            >
              <option disabled selected>
                -- Selecione um tipo --
              </option>
              <option value={'Em Estudo / A Iniciar'}>
                Em Estudo / A Iniciar
              </option>
              <option value={'Em Andamento'}>Em Andamento</option>
              <option value={'Paralisado / Suspenso'}>
                Paralisado / Suspenso
              </option>
              <option value={'Concluído'}>Concluído</option>
            </Form.Select>
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="exampleTextarea">
              Principais Serviços{' '}
            </label>
            <JoditEditor
              ref={editor}
              value={PrincipaisServiços}
              onBlur={newContent => setPrincipaisServiços(newContent)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="exampleTextarea">
              Informações Adicionais{' '}
            </label>
            <JoditEditor
              ref={editor}
              value={InformaçõesAdicionais}
              onBlur={newContent => setInformaçõesAdicionais(newContent)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-outline-secondary"
          >
            Fechar
          </button>
          <button
            type="button"
            onClick={() => {
              EditarInformaçõesBásicas();
              handleClose();
            }}
            className="btn btn-outline-primary"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Modal>
    </Stack>
  );
};

export default InfoBasicasObras;
