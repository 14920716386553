// store/usuarioSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UsuarioState {
  usuarioEmail: string;
  usuarioLogado: boolean;
}

const initialState: UsuarioState = {
  usuarioEmail: '',
  usuarioLogado: false
};

export const usuarioSlice = createSlice({
  name: 'usuario',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<string>) => {
      state.usuarioLogado = true;
      state.usuarioEmail = action.payload;
    },
    logOut: state => {
      state.usuarioLogado = false;
      state.usuarioEmail = '';
    }
  }
});

export const { logIn, logOut } = usuarioSlice.actions;

export default usuarioSlice.reducer;
