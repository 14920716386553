import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { getFirestore, doc, updateDoc, arrayUnion, getDoc, arrayRemove } from 'firebase/firestore';
import moment from 'moment';
import 'moment/locale/pt-br';

interface CardProps {
  id: string;
  img: string;
  usuario: string;
  titulo: string;
  tipo: string;
  estado: string;
  cidade: string;
  regiao: string;
  idshows: string;
  nome: string;
  nomeusuario: string;
  data: number;
  local: string;
  vizualizações: number;
  curtidas: number;
  resumo: string;
}

const Card: React.FC<CardProps> = ({
  id,
  usuario,
  nomeusuario,
  titulo,
  estado,  
  idshows,
  nome,
  data,
  local,
  vizualizações,
  curtidas,
  resumo,
}) => {
  const storage = getStorage();
  const auth = getAuth();
  const db = getFirestore();
  const [urlImagens, setUrlImagens] = useState<string>('');
  const [urlAvatar, setUrlAvatar] = useState<string | undefined>(undefined);
  const [urlImagemNova, setUrlImagemNova] = useState<string | undefined>(undefined);
  const [isExpanded, setIsExpanded] = useState(false);
  const [likeCount, setLikeCount] = useState<number>(curtidas);
  const [commentCount, setCommentCount] = useState<number>(0); // Número de comentários
  const [user, setUser] = useState<User | null>(null);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    getDownloadURL(ref(storage, `Shows/${idshows}/FotoShow/FotoShow`))
      .then((url) => setUrlImagens(url))
      .catch((error) => {
        console.error('Erro ao obter URL da imagem:', error.message);
      });
  }, [idshows, storage]);

  useEffect(() => {
    getDownloadURL(
      ref(storage, `Usuários/${usuario}/FotoPerfil/FotoPerfil`)
    )
      .then((url) => setUrlAvatar(url))
      .catch((error) => {
        console.error('Erro ao obter URL do avatar:', error.message);
      });
  }, [usuario, storage]);

  useEffect(() => {
    getDownloadURL(ref(storage, `Usuários/user defalt.png`)).then((url) =>
      setUrlImagemNova(url)
    );
  }, [storage]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email) {
        setUser(user);
        checkIfLiked(user.email);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const checkIfLiked = async (email: string) => {
    const docRef = doc(db, 'Shows', idshows);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.usuariocurtida && data.usuariocurtida.includes(email)) {
        setIsLiked(true);
      }
      setLikeCount(data.curtidas); // Atualiza o número de curtidas
    }
  };

  useEffect(() => {
    const loadShowData = async () => {
      try {
        const docRef = doc(db, 'Shows', idshows);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setLikeCount(data.curtidas);
          setCommentCount(data.comments ? data.comments.length : 0); // Atualiza o número de comentários
          if (user && data.usuariocurtida && data.usuariocurtida.includes(user.email)) {
            setIsLiked(true);
          }
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Erro ao carregar o número de curtidas ou comentários:', error.message);
        } else {
          console.error('Erro desconhecido ao carregar o número de curtidas ou comentários');
        }
      }
    };

    loadShowData();
  }, [db, idshows, user]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLikeToggle = async () => {
    if (user && user.email) {
      const docRef = doc(db, 'Shows', idshows);

      try {
        if (isLiked) {
          // Descurtir
          await updateDoc(docRef, {
            usuariocurtida: arrayRemove(user.email),
            curtidas: likeCount - 1
          });
          setIsLiked(false);
          setLikeCount((prevCount) => prevCount - 1);
        } else {
          // Curtir
          await updateDoc(docRef, {
            usuariocurtida: arrayUnion(user.email),
            curtidas: likeCount + 1
          });
          setIsLiked(true);
          setLikeCount((prevCount) => prevCount + 1);
        }
      } catch (error) {
        console.error('Erro ao curtir/descurtir o evento:', error);
      }
    } else if (!user) {
      alert('Você precisa estar logado para curtir ou descurtir este evento.');
    }
  };


  const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `Confira o evento ${titulo} em ${local}, ${moment(data)
      .format('dddd')
      .replace(/^\w/, (c) => c.toUpperCase())}, dia ${moment(data).format(
        'DD/MM/YYYY'
      )}. Saiba mais em: http://zuarz.com/Shows/${id}`
  )}`;

  return (
    <div className="col-sm-12 col-md-3 my-4">
      <div className="float-left">
        <div className="d-flex align-items-center my-1">
          <img
            src={urlAvatar ? urlAvatar : urlImagemNova}
            alt="Avatar"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              marginRight: '10px'
            }}
          />
          <div
            className="mb-0"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 1,
              fontSize: '12px',
              lineHeight: '1.2'
            }}
          >
            <h5 style={{ margin: '0', fontSize: '14px' }}>{nome}</h5>
            <p style={{ margin: '0', fontSize: '12px' }}>{nomeusuario}</p>
          </div>
          <h5 className="mb-0 text-danger" style={{ fontSize: '14px' }}>
            Shows
          </h5>
        </div>
        <img
          src={urlImagens}
          alt="Imagem do evento"
          onDoubleClick={handleLikeToggle}
          style={{
            width: '100%',
            height: '300px',
            objectFit: 'cover',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        />
        <div className="row">
          <div className="col-12 mt-1 d-flex align-items-center my-1">
            <span style={{ marginRight: '10px' }}>
              <i className="far fa-eye"></i> {vizualizações}
            </span>
            <span style={{ marginRight: '10px' }}>
              <i className="fas fa-comment"></i> {commentCount} {/* Exibe o número de comentários */}
            </span>
            <span style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handleLikeToggle}>
              <i style={{ color: isLiked ? 'red' : 'inherit' }} className={isLiked ? 'fas fa-heart' : 'far fa-heart'}></i> {likeCount}
            </span>
            <span className="ms-auto text-end">{estado}</span>
          </div>
        </div>
        <div className="mt-1">
          <h5
            className="text-center mb-1 text-danger"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {titulo}
          </h5>
          <h5
            className="text-center mb-1 text-primary"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {moment(data)
              .format('dddd')
              .replace(/^\w/, (c) => c.toUpperCase())}{' '}
            {moment(data)
              .format('DD')}{' '}
            {moment(data)
              .format('MMM')
              .replace(/^\w/, (c) => c.toUpperCase())}{' '}
          </h5>
          <h5
            className="text-center mb-1 text-primary"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {local.charAt(0).toUpperCase() + local.slice(1)}
          </h5>
          <div
            className={`mb-2 ${isExpanded ? '' : 'text-truncate'}`}
            style={{
              overflow: 'hidden',
              WebkitLineClamp: isExpanded ? 'none' : 15,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {isExpanded ? (
              <div dangerouslySetInnerHTML={{ __html: resumo }} />
            ) : (
              <div>{resumo}</div>
            )}
          </div>
          <div className="d-flex justify-content-end mt-2">
            <button className="btn btn-secondary btn-sm me-2" onClick={toggleExpand}>
              {isExpanded ? <i className="fas fa-minus fa-1x" /> : <i className="fas fa-plus fa-1x" />}
            </button>
            <Link
              to={`/Shows/${id}`}
              className="btn btn-primary btn-sm me-2">
              <i className="fas fa-info-circle fa-1x" />
            </Link>
            <a
              href={whatsappLink}
              className="btn btn-success btn-sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp fa-1x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
