import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'config/firebase';

/** Importação do Tema */
import { Stack } from 'react-bootstrap';
import NavBarsObrasDetalhes from 'components/navbars/NavBarsObrasDetalhes';

interface InformacoesBasicas {
  TituloReduzido: string;
  // Adicione outros campos conforme necessário
}

const DetalhesObras = () => {
  const { id } = useParams();
  const [, setInformacoesBasicas] = useState<InformacoesBasicas | null>(null);
  const [tituloReduzido, setTituloReduzido] = useState<string>('');

  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .get()
      .then(resultado => {
        const data = resultado.data();
        if (data) {
          const infosBasicas = data.InformaçõesBásicas as InformacoesBasicas; // Assegura que o dado é do tipo InformacoesBasicas
          setInformacoesBasicas(infosBasicas);
          setTituloReduzido(infosBasicas.TituloReduzido);
        }
      });
  }, []);

  return (
    <Stack className="content-min-h">
      <NavBarsObrasDetalhes />
      <div className="container-fluid">
        <section className="col-md-12 mb-3 text-center">
          <h2 className="ml-3 text-dark text-center font-weight-bold">
            {tituloReduzido}
          </h2>
        </section>
      </div>
    </Stack>
  );
};

export default DetalhesObras;
