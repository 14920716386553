import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import showIcon from '../../../../assets/img/icons/Festa.png'; // Substitua pelo caminho da sua imagem

const CriarEventos: React.FC = () => {
  const navigate = useNavigate(); // Hook para navegação programática

  const handleNavigate = () => {
    navigate('/inserir-eventos/shows');
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Criar Eventos</h2>
      <div className="d-flex justify-content-center">
        <Card style={{ width: '18rem', textAlign: 'center' }}>
          <Card.Body>
            <Card.Img
              variant="top"
              src={showIcon}
              alt="Show Icon"
              className="hover-effect" // Adiciona a classe CSS
              style={{
                borderRadius: '50%',
                width: '150px',
                height: '150px',
                margin: '0 auto',
                transition: 'transform 0.3s ease-in-out' // Transição suave
              }}
            />
            <Card.Title className="mt-3">Acrescentar Festa ou Show</Card.Title>
            <Button variant="primary" onClick={handleNavigate}>
              Acrescentar
            </Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CriarEventos;
