import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'config/firebase';

/** Importação do Tema */
import { Stack } from 'react-bootstrap';
import NavBarsObrasDetalhes from 'components/navbars/NavBarsObrasDetalhes';

/** Moment */
import moment from 'moment';
import 'moment/locale/pt-br';

interface InformacoesBasicas {
  TituloReduzido: string;
  TituloCompleto: string;
  InformaçõesAdicionais: string;
  PrincipaisServiços: string;
  Status: string;
  criacao: string;
}

interface CronogramaInter {
  DataInicio: string;
  DataTermino: string;
  Suspenso: string;
  PrazoDias: string;
  CronogramaFísico: string;
}

interface SuspensaoInter {
  id: string;
  InicioSuspensao: string;
  PrazoSuspenso: string;
}

const AnáliseObras = () => {
  //#region Formatar Número
  const numberDecimalFormat = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);

  const numberIntegerFormat = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      maximumFractionDigits: 0
    }).format(value);

  //#endregion

  const [, setInformacoesBasicas] = useState<InformacoesBasicas | null>(null);
  const [TituloReduzido, setTituloReduzido] = useState<string>('');
  const [, setTituloCompleto] = useState<string>('');
  const [, setCriaçãoObra] = useState<string>('');
  const [Status, setStatus] = useState<string>('');
  const [, setPrincipaisServiços] = useState<string>('');
  const [, setInformaçõesAdicionais] = useState<string>('');
  const { id } = useParams();

  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .get()
      .then(resultado => {
        const data = resultado.data();
        if (data) {
          const infosBasicas = data.InformaçõesBásicas as InformacoesBasicas;
          setInformacoesBasicas(infosBasicas);
          setTituloReduzido(infosBasicas.TituloReduzido);
          setTituloCompleto(infosBasicas.TituloCompleto);
          setCriaçãoObra(infosBasicas.criacao);
          setInformaçõesAdicionais(infosBasicas.InformaçõesAdicionais);
          setPrincipaisServiços(infosBasicas.PrincipaisServiços);
          setStatus(infosBasicas.Status);
        }
      });
  }, [id]);

  //#region Cronograma
  const [, setCronograma] = useState<CronogramaInter | null>(null);
  const [DataInicio, setDataInicio] = useState<string>('');
  const [DataTermino, setDataTermino] = useState<string>('');
  const [, setSuspenso] = useState<string>('');
  const [PrazoDias, setPrazoDias] = useState<string>('');

  /** Buscar Informações Cronograma */
  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .get()
      .then(resultado => {
        const data = resultado.data();
        if (data) {
          const cronograma = data.Cronograma as CronogramaInter;
          setCronograma(cronograma);
          setDataInicio(cronograma.DataInicio);
          setDataTermino(cronograma.DataTermino);
          setSuspenso(cronograma.Suspenso);
          setPrazoDias(cronograma.PrazoDias);
        }
      });
  }, [id]);
  //#endregion

  //#region Suspensões
  const listaSuspensões: SuspensaoInter[] = [];
  const [Suspensão, setSuspensão] = useState<SuspensaoInter[]>([]);
  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .collection('Suspensão')
      .orderBy('InicioSuspensão')
      .get()
      .then(async resultado => {
        await resultado.docs.forEach(doc => {
          listaSuspensões.push({
            id: doc.id,
            ...doc.data()
          } as SuspensaoInter);
        });
        setSuspensão(listaSuspensões);
      });
  }, [id]);
  //#endregion

  const today = moment(); // Definindo a data atual

  return (
    <Stack className="content-min-h">
      <NavBarsObrasDetalhes />
      <div className="container-fluid">
        <section className="col-md-12 mb-3 text-center">
          <h3 className="text-danger mb-3">{TituloReduzido}</h3>
          <h3>Análise</h3>

          {/** Status da Obra*/}
          <div className="col-md-12 row my-5">
            <div className="col-md-12">
              <h3 className="card-title text-primary mb-3">
                Informações Gerais
              </h3>
              <div className="col-md-12 d-flex justify-content-between">
                <div className="col-md-4 card mb-4 mx-2">
                  <div className="card-body">
                    <h3 className="card-title">Status da Obra</h3>
                    <h5 className="float-right">{Status}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/** Cronograma Físico */}
          <div className="col-md-12 row my-5">
            <div className="col-md-12">
              <h3 className="card-title text-primary mb-3">
                Cronograma Físico
              </h3>
            </div>
            <div className="col-md-12 d-flex justify-content-between">
              <div className="col-md-4 card mb-4 mx-2">
                <div className="card-body">
                  <h3 className="card-title">Data de Início</h3>
                  <h5 className="float-right">
                    {moment(DataInicio).format('L')}
                  </h5>
                </div>
              </div>
              <div className="col-md-4 card mb-4 mx-2">
                <div className="card-body">
                  <h3 className="card-title">Data de Término</h3>
                  <h5 className="float-right">
                    {moment(DataTermino).format('L')}
                  </h5>
                </div>
              </div>
              <div className="col-md-4 card mb-4 mx-2">
                <div className="card-body">
                  <h3 className="card-title">Prazo Consumido</h3>
                  <h5 className="float-right">
                    {Status === 'Em Andamento' &&
                      numberDecimalFormat(
                        ((Number(moment(today).diff(DataInicio, 'days')) -
                          Number(
                            numberIntegerFormat(
                              Suspensão.reduce(
                                (acc, p) => acc + parseFloat(p.PrazoSuspenso),
                                0
                              )
                            )
                          )) /
                          Number(PrazoDias)) *
                          100
                      ) + '%'}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Stack>
  );
};

export default AnáliseObras;
