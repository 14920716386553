import { RootState } from 'store';
import firebase from 'config/firebase';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from 'store/usuarioSlice';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getDoc, doc, getFirestore } from 'firebase/firestore';

/** Importação do Tema */
import Avatar from 'components/base/Avatar';
import { useState, useEffect } from 'react';
import { Card, Dropdown, Form, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const usuarioEmail = useSelector(
    (state: RootState) => state.usuario.usuarioEmail
  );
  const usuarioLogado = useSelector(
    (state: RootState) => state.usuario.usuarioLogado
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storage = getStorage();
  const firestore = getFirestore();

  const fazerLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('Usuário deslogado com sucesso');
        dispatch(logOut()); // Atualiza o estado no Redux
        navigate('/login'); // Redireciona para a página de login ou outra página de sua escolha
      })
      .catch(erro => {
        console.error('Erro ao deslogar:', erro);
      });
  };

  const [urlImagens, setUrlImagens] = useState<string | undefined>(undefined);
  const [usuarioNome, setUsuarioNome] = useState<string | undefined>(undefined);
  const [NomeUsuario, setNomeUsuario] = useState<string | undefined>(undefined); 

  // Carrega a foto de perfil e o nome do usuário
  useEffect(() => {
    if (usuarioEmail) {
      const fetchData = async () => {
        try {
          // Primeiro, carregar o nome do usuário
          const docRef = doc(firestore, 'Usuários', usuarioEmail);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUsuarioNome(userData.InfoPessoais.Nome);
            setNomeUsuario(userData.InfoPessoais.NomeUsuario);
          } else {
            console.error('Documento do usuário não encontrado');
          }

          // Em seguida, tentar carregar a foto de perfil
          try {
            const url = await getDownloadURL(
              ref(storage, `Usuários/${usuarioEmail}/FotoPerfil/FotoPerfil`)
            );
            setUrlImagens(url);
          } catch (imageError) {
            console.warn('Foto de perfil não encontrada, utilizando imagem padrão');
            setUrlImagens(undefined); // Deixa o valor como undefined para usar a imagem padrão
          }
        } catch (error: unknown) {
          if (error instanceof Error) {
            console.error('Erro ao obter dados do usuário:', error.message);
          } else {
            console.error('Erro desconhecido ao obter dados do usuário');
          }
        }
      };

      fetchData();
    }
  }, [usuarioEmail, storage, firestore]);

  const [urlImagemNova, setUrlImagemNova] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    getDownloadURL(ref(storage, `Usuários/user defalt.png`))
      .then(url => setUrlImagemNova(url))
      .catch(error => {
        console.error('Erro ao carregar a imagem padrão:', error.message);
        setUrlImagemNova(undefined); // Usar undefined se não conseguir carregar a imagem padrão
      });
  }, [storage]);

  const [navItems] = useState([
    {
      label: 'Perfil',
      icon: 'user',
      link: '/perfil' // Adicionando o caminho para a página de perfil
    },
    {
      label: 'Inserir Eventos',
      icon: 'folder-plus',
      link: '/inserir-eventos'
    },
    {
      label: 'Inserir Locais',
      icon: 'folder-plus',
      link: '/inserir-locais'
    },
    {
      label: 'Inserir Artistas',
      icon: 'folder-plus',
      link: '/inserir-artistas'
    }
  ]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={urlImagens ? urlImagens : urlImagemNova} size="xl" />
            {usuarioLogado && <h6 className="text-black">{usuarioNome || 'Usuário'} - {NomeUsuario || 'Nome de Usuário'}</h6>}
            {usuarioLogado && <h6 className="text-black">{usuarioEmail}</h6>}
            {!usuarioLogado && <h6 className="text-black">Novo Usuário</h6>}
          </div>
          {usuarioLogado && (
            <div className="mb-3 mx-3">
              <Form.Control
                type="text"
                placeholder="Update your status"
                size="sm"
              />
            </div>
          )}
          {usuarioLogado && (
            <div style={{ height: '10rem' }}>
              <Scrollbar>
                <Nav className="nav flex-column mb-2 pb-1">
                  {navItems.map(item => (
                    <Nav.Item key={item.label}>
                      <Nav.Link as={Link} to={item.link} className="px-3">
                        <FeatherIcon
                          icon={item.icon}
                          size={16}
                          className="me-2 text-900"
                        />
                        <span className="text-1000">{item.label}</span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Scrollbar>
            </div>
          )}
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          {!usuarioLogado && (
            <Nav className="nav flex-column my-3">
              <Nav.Item>
                <Nav.Link href="/Cadastrar" className="px-3">
                  <FeatherIcon
                    icon="user-plus"
                    size={16}
                    className="me-2 text-900"
                  />
                  <span>Cadastre-se</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          )}
          <hr />
          <div className="px-3">
            {usuarioLogado && (
              <Link
                to="/"
                onClick={fazerLogout}
                className="btn btn-phoenix-secondary d-flex flex-center w-100"
              >
                <FeatherIcon icon="log-out" className="me-2" size={16} />
                Sair
              </Link>
            )}
            {!usuarioLogado && (
              <Link
                to="/Login"
                className="btn btn-phoenix-secondary d-flex flex-center w-100"
              >
                <FeatherIcon icon="log-in" className="me-2" size={16} />
                Faça Login
              </Link>
            )}
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-600">
            <Link className="text-600 me-1" to="#!">
              Privacy policy
            </Link>
            •
            <Link className="text-600 mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-600 ms-1" to="#!">
              Cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
