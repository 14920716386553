import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'config/firebase';

/** Importação do Tema */
import { Stack } from 'react-bootstrap';
import NavBarsObrasDetalhes from 'components/navbars/NavBarsObrasDetalhes';

/** Moment */
import 'moment/locale/pt-br';

interface InformacoesBasicas {
  TituloReduzido: string;
  TituloCompleto: string;
  InformaçõesAdicionais: string;
  PrincipaisServiços: string;
  Status: string;
  criacao: string;
}

const CronogramaFinanceiroObras = () => {
  const [, setInformacoesBasicas] = useState<InformacoesBasicas | null>(null);
  const [TituloReduzido, setTituloReduzido] = useState<string>('');
  const { id } = useParams();

  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .get()
      .then(resultado => {
        const data = resultado.data();
        if (data) {
          const infosBasicas = data.InformaçõesBásicas as InformacoesBasicas;
          setInformacoesBasicas(infosBasicas);
          setTituloReduzido(infosBasicas.TituloReduzido);
        }
      });
  }, []);

  return (
    <Stack className="content-min-h">
      <NavBarsObrasDetalhes />
      <div className="container-fluid">
        <section className="col-md-12 mb-3 text-center">
          <h3 className="text-danger mb-3">{TituloReduzido}</h3>
          <h3>Cronograma Financeiro</h3>
        </section>
      </div>
    </Stack>
  );
};

export default CronogramaFinanceiroObras;
