import React, { useState, useEffect } from 'react';
import firebase from 'config/firebase';
import 'firebase/auth';
import { Col, Form, Row, Modal, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import Button from 'components/base/Button';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { serverTimestamp, getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

interface FirebaseError {
  code: string;
  message: string;
}

const Cadastrar = () => {
  const [email, setEmail] = useState<string>('');
  const [senha, setSenha] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [nomeUsuario, setNomeUsuario] = useState<string>('');
  const [repetirsenha, setRepetirSenha] = useState<string>('');
  const [termosAceitos, setTermosAceitos] = useState<boolean>(false);
  const [msgTipo, setMsgTipo] = useState<string | undefined>();
  const [msg, setMsg] = useState<string | undefined>();
  const [carregando, setCarregando] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nomeUsuarioDisponivel, setNomeUsuarioDisponivel] = useState<boolean | null>(null);

  const navigate = useNavigate();
  const db = getFirestore(); // Use getFirestore para acessar o Firestore

  const verificarNomeUsuario = async (nome: string) => {
    const nomeUsuarioLowerCase = nome.toLowerCase();
    const nomeUsuarioRef = doc(db, 'NomesUsuarios', nomeUsuarioLowerCase);
    const docSnap = await getDoc(nomeUsuarioRef);
    return !docSnap.exists();
  };

  useEffect(() => {
    if (nomeUsuario) {
      const verificarDisponibilidade = async () => {
        const disponivel = await verificarNomeUsuario(nomeUsuario);
        setNomeUsuarioDisponivel(disponivel);
      };
      verificarDisponibilidade();
    } else {
      setNomeUsuarioDisponivel(null);
    }
  }, [nomeUsuario]);

  const cadastrar = async () => {
    if (!nome || !nomeUsuario || !email || !senha || !repetirsenha || !termosAceitos) {
      setMsg('Todos os campos devem ser preenchidos.');
      setMsgTipo('Erro');
      setShowModal(true);
      return;
    }

    if (senha !== repetirsenha) {
      setMsg('As senhas não coincidem.');
      setMsgTipo('Erro');
      setShowModal(true);
      return;
    }

    if (nomeUsuarioDisponivel === false) {
      setMsg('Este nome de usuário já está em uso. Escolha outro.');
      setMsgTipo('Erro');
      setShowModal(true);
      return;
    }

    setCarregando(1);
    setMsgTipo(undefined);

    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, senha);
      const usuarioAtual = userCredential.user;

      if (usuarioAtual && usuarioAtual.email) {
        const nomeUsuarioLowerCase = nomeUsuario.toLowerCase();

        // Salvar o nome de usuário na coleção NomesUsuarios
        await setDoc(doc(db, 'NomesUsuarios', nomeUsuarioLowerCase), {
          email: email
        });

        // Adicionar informações adicionais na coleção Usuários
        const userInfo = {
          DataCriacao: serverTimestamp(),
          InfoPessoais: {
            Nome: nome,
            Email: email,
            NomeUsuario: nomeUsuarioLowerCase,
            FotoPerfilUrl: '', // Adicione aqui a URL da foto de perfil se houver
            Sexo: '', // Adicione o sexo aqui se necessário
            Telefone: '', // Adicione o telefone aqui se necessário
            Aniversario: '', // Adicione a data de aniversário aqui se necessário
          },
        };

        await setDoc(doc(db, 'Usuários', usuarioAtual.email), userInfo);

        setMsg('Usuário cadastrado com sucesso!');
        setMsgTipo('Sucesso');
        setShowModal(true);

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    } catch (erro) {
      setCarregando(0);
      setMsgTipo('Erro');

      const firebaseError = erro as FirebaseError;

      if (firebaseError.code === 'auth/email-already-in-use') {
        setMsg('Este email já está sendo utilizado por outro usuário.');
      } else {
        switch (firebaseError.message) {
          case 'Password should be at least 6 characters':
            setMsg('A senha deve ter pelo menos 6 caracteres');
            break;
          case 'The email address is badly formatted.':
            setMsg('O formato do seu email é inválido');
            break;
          default:
            setMsg('Não foi possível cadastrar. Verifique suas informações.');
            break;
        }
      }
      setShowModal(true);
    } finally {
      setCarregando(0);
    }
  };

  return (
    <AuthSimpleLayout>
      <>
        <div className="text-center mb-7">
          <h3 className="text-1000">Cadastre-se</h3>
          <p className="text-700">Crie a sua conta</p>
        </div>
        <Form>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="name">Nome</Form.Label>
            <Form.Control id="name" type="text" placeholder="Nome" onChange={e => setNome(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="nameuser">Nome de Usuário</Form.Label>
            <Form.Control id="nameuser" type="text" placeholder="Nome de Usuário" onChange={e => setNomeUsuario(e.target.value)} />
            {nomeUsuarioDisponivel !== null && (
              <div style={{ color: nomeUsuarioDisponivel ? 'green' : 'red' }}>
                {nomeUsuarioDisponivel ? 'Nome de usuário disponível!' : 'Nome de usuário já está em uso.'}
              </div>
            )}
          </Form.Group>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="email">Email</Form.Label>
            <Form.Control id="email" type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} />
          </Form.Group>
          <Row className="g-3 mb-3">
            <Col sm={6} lg={6}>
              <Form.Group>
                <Form.Label htmlFor="password">Senha</Form.Label>
                <Form.Control id="password" type="password" placeholder="Senha" onChange={e => setSenha(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={6} lg={6}>
              <Form.Group>
                <Form.Label htmlFor="confirmPassword">Confirmar Senha</Form.Label>
                <Form.Control id="confirmPassword" type="password" placeholder="Confirmar Senha" onChange={e => setRepetirSenha(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Form.Check type="checkbox" className="mb-3">
            <Form.Check.Input type="checkbox" name="termsService" id="termsService" onChange={e => setTermosAceitos(e.target.checked)} />
            <Form.Check.Label htmlFor="termsService" className="fs-9 text-none">
              Eu aceito os <Link to="#!">termos </Link>e a{' '}
              <Link to="#!">política de privacidade.</Link>
            </Form.Check.Label>
          </Form.Check>
          <Button variant="primary" className="w-100 mb-3 btn-block" onClick={cadastrar} disabled={carregando === 1 || nomeUsuarioDisponivel === false}>
            {carregando === 1 ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Cadastrar'}
          </Button>
          <div className="text-center">
            <Link to={`/login`} className="fs-9 fw-bold">
              Eu já possuo uma conta
            </Link>
          </div>
        </Form>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Body className="text-center">
            <AiOutlineInfoCircle size={50} className={msgTipo === 'Sucesso' ? 'text-success my-3' : 'text-primary my-3'} />
            <h4>{msgTipo === 'Sucesso' ? 'Sucesso' : 'Atenção'}</h4>
            <p>{msg}</p>
            <Button variant={msgTipo === 'Sucesso' ? 'success' : 'primary'} onClick={() => setShowModal(false)} className="mt-3">
              Confirmar
            </Button>
          </Modal.Body>
        </Modal>
      </>
    </AuthSimpleLayout>
  );
};

export default Cadastrar;
