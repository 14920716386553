import React, { useState, useEffect } from 'react';
import {
  Col,
  Form,
  Row,
  FloatingLabel,
  Modal,
  Button,
  Spinner
} from 'react-bootstrap';
import firebase from 'config/firebase';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { useSelector } from 'react-redux';
import DatePicker from 'components/base/DatePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoadScript } from '@react-google-maps/api';
import imageCompression from 'browser-image-compression';

const CriarShows = () => {
  const [titulo, setTitulo] = useState<string>('');
  const [data, setData] = useState<string>('');
  const [localSelecionado, setLocalSelecionado] = useState<string>('');
  const [locais, setLocais] = useState<{ nome: string, endereco: string }[]>([]);
  const [publico, setPublico] = useState<string>('');
  const [artistas, setArtistas] = useState<{ nome: string }[]>([]);
  const [artistasSelecionados, setArtistasSelecionados] = useState<string[]>([]);
  const [euvouInput] = useState<string>('');
  const [usuariocurtidaInput] = useState<string>('');
  const [estilosInput, setEstilosInput] = useState<string>('');
  const [classificacao, setClassificacao] = useState<number | string>('');
  const [classificacaoLivre, setClassificacaoLivre] = useState<boolean>(false);
  const [detalhes, setDetalhes] = useState<string>('');
  const [resumo, setResumo] = useState<string>('');
  const [fotoNova, setFotoNova] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const usuarioEmail = useSelector(
    (state: { usuario: { usuarioEmail: string } }) => state.usuario.usuarioEmail
  );
  const [carregando, setCarregando] = useState<boolean>(false);
  const [msgTipo, setMsgTipo] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [atualizacao, setAtualizacao] = useState<string>('livre');
  const [membros, setMembros] = useState<{ Nome: string; NomeUsuario: string }[]>([]);
  const [membrosSelecionados, setMembrosSelecionados] = useState<{ Nome: string; NomeUsuario: string }[]>([]);

  const storage = getStorage();
  const db = getFirestore();

  useEffect(() => {
    const fetchLocais = async () => {
      const locaisSnapshot = await getDocs(collection(db, 'Locais'));
      const locaisList = locaisSnapshot.docs.map(doc => doc.data() as { nome: string, endereco: string });
      setLocais(locaisList);
    };

    const fetchMembros = async () => {
      const membrosSnapshot = await getDocs(collection(db, 'Usuários'));
      const membrosList = membrosSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          Nome: data.InfoPessoais.Nome,
          NomeUsuario: data.InfoPessoais.NomeUsuario,
        };
      });
      setMembros(membrosList);
    };

    const fetchArtistas = async () => {
      const artistasSnapshot = await getDocs(collection(db, 'Artistas'));
      const artistasList = artistasSnapshot.docs.map(doc => doc.data() as { nome: string });
      setArtistas(artistasList);
    };

    fetchLocais();
    fetchMembros();
    fetchArtistas();
  }, [db]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        setFotoNova(compressedFile);
        const preview = URL.createObjectURL(compressedFile);
        setPreviewUrl(preview);
      } catch (error) {
        console.error('Erro ao compactar a imagem:', error);
      }
    }
  };

  const handleMoverArtista = () => {
    const selectElement = document.getElementById('artistasSelect') as HTMLSelectElement;
    const selectedOptions = Array.from(selectElement.selectedOptions);
    const novosArtistas = selectedOptions.map(option => option.value);

    setArtistas(artistas.filter(artista => !novosArtistas.includes(artista.nome)));
    setArtistasSelecionados([...artistasSelecionados, ...novosArtistas]);
  };

  const handleRemoverArtista = () => {
    const selectElement = document.getElementById('artistasSelecionados') as HTMLSelectElement;
    const selectedOptions = Array.from(selectElement.selectedOptions);
    const artistasARemover = selectedOptions.map(option => option.value);

    setArtistasSelecionados(artistasSelecionados.filter(artista => !artistasARemover.includes(artista)));
    setArtistas([...artistas, ...artistasARemover.map(nome => ({ nome }))]);
  };

  const handleMoverMembro = () => {
    const selectElement = document.getElementById('membrosSelect') as HTMLSelectElement;
    const selectedOptions = Array.from(selectElement.selectedOptions);
    const novosMembros = selectedOptions.map(option => ({
      Nome: option.dataset.nome!,
      NomeUsuario: option.dataset.nomeusuario!,
    }));

    setMembros(membros.filter(membro => !novosMembros.find(nm => nm.Nome === membro.Nome && nm.NomeUsuario === membro.NomeUsuario)));
    setMembrosSelecionados([...membrosSelecionados, ...novosMembros]);
  };

  const handleRemoverMembro = () => {
    const selectElement = document.getElementById('membrosSelecionados') as HTMLSelectElement;
    const selectedOptions = Array.from(selectElement.selectedOptions);
    const membrosARemover = selectedOptions.map(option => ({
      Nome: option.dataset.nome!,
      NomeUsuario: option.dataset.nomeusuario!,
    }));

    setMembrosSelecionados(membrosSelecionados.filter(membro => !membrosARemover.find(mr => mr.Nome === membro.Nome && mr.NomeUsuario === membro.NomeUsuario)));
    setMembros([...membros, ...membrosARemover]);
  };

  const cadastrar = () => {
    if (!fotoNova) {
      setMsgTipo('Erro');
      setText('Uma foto deve ser selecionada.');
      setShowModal(true);
      return;
    }
    if (!titulo) {
      setMsgTipo('Erro');
      setText('O campo "Título do Evento" deve ser preenchido.');
      setShowModal(true);
      return;
    }
    if (!localSelecionado) {
      setMsgTipo('Erro');
      setText('O campo "Local" deve ser selecionado.');
      setShowModal(true);
      return;
    }
    if (!data) {
      setMsgTipo('Erro');
      setText('O campo "Data" deve ser preenchido.');
      setShowModal(true);
      return;
    }
    if (artistasSelecionados.length === 0) {
      setMsgTipo('Erro');
      setText('Você deve selecionar pelo menos um artista.');
      setShowModal(true);
      return;
    }

    const dataEvento = new Date(data);
    if (isNaN(dataEvento.getTime())) {
      setMsgTipo('Erro');
      setText('O campo "Data" deve conter uma data válida.');
      setShowModal(true);
      return;
    }

    const resumoHtml = resumo.replace(/\n/g, '<br/>').replace(/\r/g, '');

    setCarregando(true);

    const CadastrarShows = firebase.firestore().collection('Shows').doc();
    const PegarID = CadastrarShows.id;
    const euvouArray = euvouInput.split(',').map(euvou => euvou.trim());
    const usuariocurtidaArray = usuariocurtidaInput
      .split(',')
      .map(usuariocurtida => usuariocurtida.trim());
    const estilosArray = estilosInput.split(',').map(estilo => estilo.trim());
    const imageRef = ref(storage, `Shows/${PegarID}/FotoShow/${'FotoShow'}`);

    uploadBytes(imageRef, fotoNova).then(() => {
      const localData = locais.find(local => local.nome === localSelecionado);

      CadastrarShows.set({
        titulo: titulo,
        data: dataEvento,
        local: localData?.nome || '',
        endereco: localData?.endereco || '',
        publico: publico || 'Público',
        artista: artistasSelecionados,
        estilo: estilosArray,
        classificacao: classificacaoLivre
          ? 'Classificação Livre'
          : classificacao,
        destaque: 'Não',
        detalhes: detalhes || '',
        resumo: resumoHtml || '',
        usuario: usuarioEmail,
        vizualizações: 0,
        curtidas: 0,
        usuariocurtida: usuariocurtidaArray,
        euvou: euvouArray,
        aprovado: 'Sim',
        idshows: PegarID,
        criação: new Date(),
        atualizacao: atualizacao,
        membrosAutorizados: membrosSelecionados.map(m => `${m.Nome} (${m.NomeUsuario})`)
      })
        .then(() => {
          setCarregando(false);
          setMsgTipo('sucesso');
          setText('O evento foi publicado com sucesso!');
          setShowModal(true);

          setTitulo('');
          setData('');
          setLocalSelecionado('');
          setPublico('');
          setEstilosInput('');
          setClassificacao('');
          setDetalhes('');
          setResumo('');
          setFotoNova(null);
          setClassificacaoLivre(false);
          setAtualizacao('livre');
          setArtistasSelecionados([]);
          setMembrosSelecionados([]);
        })
        .catch();
    });
  };

  const handleClassificacaoLivreChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClassificacaoLivre(e.target.checked);
    if (e.target.checked) {
      setClassificacao('Classificação Livre');
    } else {
      setClassificacao('');
    }
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link', 'image'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'font',
    'align',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'link',
    'image'
  ];

  return (
    <div>
      <LoadScript
        googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY"
        libraries={['places']}
      >
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
              <i className={`bi ${msgTipo === 'sucesso'
                  ? 'bi-check-circle-fill'
                  : 'bi-exclamation-triangle-fill'
                } text-primary mb-3`}
                style={{
                  fontSize: '2rem'
                }}></i>
              <h5>Atenção</h5>
              <p className="text-center">{text}</p>
              <Button variant="primary" onClick={() => setShowModal(false)}>
                Confirmar
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Form className="mb-9">
          <Row className="justify-content-between align-items-end g-3 mb-5">
            <Col xs={12} sm="auto" xl={8}>
              <h2 className="mb-0">Inserir um Show</h2>
            </Col>
          </Row>
          <Row className="g-5">
            <Col xl={8}>
              <Row className="gx-3 gy-4">
                <h4 className="mb-0">Detalhes do Evento</h4>
                <Col sm={6} md={12}>
                  <FloatingLabel
                    controlId="floatingEventInput"
                    label="Título do Evento"
                  >
                    <Form.Control
                      type="text"
                      onChange={e => setTitulo(e.target.value)}
                      value={titulo}
                      placeholder="Título do Evento"
                    />
                  </FloatingLabel>
                </Col>
                <Col sm={6}>
                  <FloatingLabel
                    controlId="floatingEstilosInput"
                    label="Estilos"
                  >
                    <Form.Control
                      type="text"
                      onChange={e => setEstilosInput(e.target.value)}
                      value={estilosInput}
                      placeholder="Digite os estilos, separados por vírgulas"
                    />
                  </FloatingLabel>
                </Col>
                <Col sm={6}>
                  <Row>
                    <Col xs={8}>
                      <FloatingLabel
                        controlId="floatingClassificacaoInput"
                        label="Classificação Etária"
                      >
                        <Form.Control
                          type="number"
                          onChange={e =>
                            setClassificacao(parseInt(e.target.value, 10) || '')
                          }
                          value={classificacaoLivre ? '' : classificacao}
                          placeholder="Digite a classificação etária"
                          disabled={classificacaoLivre}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Check
                        type="checkbox"
                        label="Classificação Livre"
                        onChange={handleClassificacaoLivreChange}
                        checked={classificacaoLivre}
                      />
                    </Col>
                  </Row>
                </Col>
                <h4 className="mb-0">Localização</h4>
                <Col sm={12}>
                  <FloatingLabel controlId="floatingLocalInput" label="Local">
                    <Form.Select
                      onChange={e => setLocalSelecionado(e.target.value)}
                      value={localSelecionado}
                      aria-label="Selecione o Local"
                    >
                      <option value="">Selecione o Local</option>
                      {locais.map((local, index) => (
                        <option key={index} value={local.nome}>
                          {local.nome} - {local.endereco}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>

                <h4 className="mt-4">Artistas Participantes</h4>
                <Col sm={12}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Todos os Artistas</Form.Label>
                        <Form.Select id="artistasSelect" multiple>
                          {artistas.map((artista, index) => (
                            <option key={index} value={artista.nome}>
                              {artista.nome}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center">
                      <Button onClick={handleMoverArtista}>&gt;</Button>
                      <Button onClick={handleRemoverArtista}>&lt;</Button>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Artistas Selecionados</Form.Label>
                        <Form.Select id="artistasSelecionados" multiple>
                          {artistasSelecionados.map((artista, index) => (
                            <option key={index} value={artista}>
                              {artista}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                <h4 className="mt-4">Permissões de Atualização</h4>
                <Col sm={12}>
                  <FloatingLabel controlId="floatingAtualizacaoInput" label="Atualização">
                    <Form.Select
                      onChange={e => setAtualizacao(e.target.value)}
                      value={atualizacao}
                      aria-label="Selecione a Permissão de Atualização"
                    >
                      <option value="livre">Livre para atualização</option>
                      <option value="determinadas">Atualização por determinadas pessoas</option>
                      <option value="criador">Só quem criou este evento pode atualizar</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>

                {atualizacao === 'determinadas' && (
                  <Col sm={12} className="mt-3">
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Todos os Usuários</Form.Label>
                          <Form.Select id="membrosSelect" multiple disabled={atualizacao !== 'determinadas'}>
                            {membros.map((membro, index) => (
                              <option key={index} data-nome={membro.Nome} data-nomeusuario={membro.NomeUsuario}>
                                {membro.Nome} - {membro.NomeUsuario}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <Button onClick={handleMoverMembro}>&gt;</Button>
                        <Button onClick={handleRemoverMembro}>&lt;</Button>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Pessoas Determinadas</Form.Label>
                          <Form.Select id="membrosSelecionados" multiple disabled={atualizacao !== 'determinadas'}>
                            {membrosSelecionados.map((membro, index) => (
                              <option key={index} data-nome={membro.Nome} data-nomeusuario={membro.NomeUsuario}>
                                {membro.Nome} - {membro.NomeUsuario}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col xs={12} className="mt-4">
                  <Form.Check
                    type="radio"
                    id="online"
                    className="form-check-inline"
                  >
                    <Form.Check.Input
                      type="radio"
                      name="statusRadio"
                      value="Publico"
                      defaultChecked
                    />
                    <Form.Check.Label htmlFor="online">
                      Público
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check
                    type="radio"
                    id="offline"
                    className="form-check-inline"
                  >
                    <Form.Check.Input
                      disabled
                      type="radio"
                      name="statusRadio"
                      value="privado"
                    />
                    <Form.Check.Label htmlFor="offline">
                      Privado
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col sm={6}>
                  <DatePicker
                    onChange={(selectedDates: Date[]) => {
                      if (selectedDates.length > 0) {
                        setData(selectedDates[0].toISOString());
                      } else {
                        setData('');
                      }
                    }}
                    value={data ? new Date(data) : undefined}
                    options={{ enableTime: true, dateFormat: 'Y-m-d H:i' }}
                  />
                </Col>
                <Col xs={12}>
                  <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>Resumo</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      placeholder="Resumo do evento"
                      onChange={e => setResumo(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <h4 className="mt-7">Descrição Completa</h4>
                <div>
                  <ReactQuill
                    value={detalhes ? detalhes : text}
                    onChange={(data: string) => setDetalhes(data)}
                    modules={modules}
                    formats={formats}
                    placeholder="Digite aqui a descrição do evento..."
                  />
                </div>
                <h4 className="mt-7">Upload da Foto:</h4>
                <Col xs={12} className="mb-3">
                  {previewUrl && (
                    <img
                      src={previewUrl}
                      alt="Pré-visualização"
                      style={{
                        width: '25%',
                        height: 'auto',
                        marginBottom: '10px'
                      }}
                    />
                  )}
                  <input
                    onChange={handleFileChange}
                    type="file"
                    accept="image/*"
                    className="form-control"
                  />
                </Col>
                <Col xs={12} sm="auto" xl={4}>
                  <div className="d-flex">
                    <Button
                      variant="primary"
                      className="px-5 w-100 text-nowrap"
                      onClick={cadastrar}
                      disabled={carregando}
                    >
                      {carregando ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Publicar Show'
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={4}></Col>
          </Row>
        </Form>
      </LoadScript>
    </div>
  );
};

export default CriarShows;
