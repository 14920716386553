import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import firebase from 'config/firebase';
import { logIn } from 'store/usuarioSlice';

/** Importação Tema */
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';

const SignInForm: React.FC<{ layout: 'simple' | 'card' | 'split' }> = ({
  layout
}) => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [msgTipo, setMsgTipo] = useState<string | null>(null);

  function Logar() {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, senha)
      .then(() => {
        setMsgTipo('Sucesso');
        setTimeout(() => {
          console.log('Logando com o email:', email);
          dispatch(logIn(email));
          navigate('/');
        }, 0);
      })
      .catch();
  }

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Login</h3>
        <h3 className="text-1000"></h3>
        <p className="text-700">Acesso a sua conta</p>
      </div>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">E-mail</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            type="email"
            className="form-icon-input"
            placeholder="nome@exemplo.com"
            onChange={e => setEmail(e.target.value)}
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Senha</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Senha"
            onChange={e => setSenha(e.target.value)}
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              Lembrar-me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            to={`/pages/authentication/${layout}/forgot-password`}
            className="fs-9 fw-semi-bold"
          >
            Esqueci minha senha
          </Link>
        </Col>
      </Row>
      <Button onClick={Logar} variant="primary" className="w-100 mb-3">
        Entrar
      </Button>
      <div className="text-center">
        <Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          Criar uma conta
        </Link>
      </div>
      <div className="msg-login text-white text-center my-3">
        {msgTipo === 'Sucesso' && (
          <p className="text-700">
            <strong>wow! </strong> Você está conectado!
          </p>
        )}
        {msgTipo === 'Erro' && (
          <p className="text-700">E-mail ou senha incorretos !</p>
        )}
      </div>
    </>
  );
};

export default SignInForm;
