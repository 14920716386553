import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilChartPie,
  UilFilesLandscapesAlt
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Página Inicial',
        icon: 'home',
        path: '/',
        pathName: 'starter-page',
        active: true
      }
    ]
  },
  {
    label: 'Menu',
    icon: UilFilesLandscapesAlt,
    pages: [
      {
        name: 'Eventos',
        icon: 'grid',
        active: true,
        pages: [
          {
            name: 'Festas e Shows',
            path: '/zuarz/inserirEvento',
            pathName: 'Obras-Todas',
            active: true
          }
        ]
      }
    ]
  }
];
