import React, { useState, useEffect, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, DocumentData, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getAuth, User } from 'firebase/auth';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Avatar from 'components/base/Avatar';
import Spinner from 'react-bootstrap/Spinner';
import { Card } from 'react-bootstrap';

interface Comment {
    text: string;
    userEmail: string;
    timestamp: string;
    likes: number;
    userProfileUrl?: string;
    userName?: string;
    userDisplayName?: string;
}

const ShowDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const db = getFirestore();
    const storage = getStorage();
    const auth = getAuth();
    const [showData, setShowData] = useState<DocumentData | null>(null);
    const [urlImagens, setUrlImagens] = useState<string>('');
    const [urlImagemNova, setUrlImagemNova] = useState<string | undefined>(undefined);
    const [urlAvatar, setUrlAvatar] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<User | null>(null);
    const [newComment, setNewComment] = useState<string>('');
    const [comments, setComments] = useState<Comment[]>([]);
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);
    const [isAvatarLoading, setIsAvatarLoading] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [hasIncrementedView, setHasIncrementedView] = useState(false);
    const [likeCount, setLikeCount] = useState<number>(0);
    const [isLiked, setIsLiked] = useState<boolean>(false);

    const usuarioEmail = useSelector((state: RootState) => state.usuario.usuarioEmail);

    const [usuarioNome, setUsuarioNome] = useState<string | undefined>(undefined);
    const [NomeUsuario, setNomeUsuario] = useState<string | undefined>(undefined);
    const [urlUsuario, setUrlUsuario] = useState<string | undefined>(undefined);

    const handleLike = async () => {
        if (user && user.email) {
            const docRef = doc(db, 'Shows', id!);

            try {
                if (isLiked) {
                    // Descurtir
                    await updateDoc(docRef, {
                        usuariocurtida: arrayRemove(user.email),
                        curtidas: likeCount - 1
                    });
                    setIsLiked(false);
                    setLikeCount((prevCount) => prevCount - 1);
                } else {
                    // Curtir
                    await updateDoc(docRef, {
                        usuariocurtida: arrayUnion(user.email),
                        curtidas: likeCount + 1
                    });
                    setIsLiked(true);
                    setLikeCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                console.error('Erro ao curtir/descurtir o evento:', error);
            }
        } else if (!user) {
            alert('Você precisa estar logado para curtir/descurtir este evento.');
        }
    };

    useEffect(() => {
        if (usuarioEmail) {
            const fetchData = async () => {
                try {
                    const url = await getDownloadURL(ref(storage, `Usuários/${usuarioEmail}/FotoPerfil/FotoPerfil`));
                    setUrlUsuario(url);

                    const docRef = doc(db, 'Usuários', usuarioEmail);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        setUsuarioNome(userData.InfoPessoais.Nome);
                        setNomeUsuario(userData.InfoPessoais.NomeUsuario);
                    }
                } catch (error) {
                    console.error('Erro ao obter URL da imagem ou nome:', error);
                }
            };

            fetchData();
        }
    }, [usuarioEmail, storage, db]);

    useEffect(() => {
        const fetchShowData = async () => {
            if (!id) {
                console.error("ID do show não encontrado");
                return;
            }

            const docRef = doc(db, 'Shows', id);
            try {
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setShowData(data);
                    setLikeCount(data.curtidas || 0);

                    if (user && data.usuariocurtida && data.usuariocurtida.includes(user.email)) {
                        setIsLiked(true);
                    }

                    if (!hasIncrementedView) {
                        const updatedViews = (data.vizualizações || 0) + 1;
                        await updateDoc(docRef, { vizualizações: updatedViews });
                        setHasIncrementedView(true); // Ensure we only increment once
                    }

                    const commentsWithUserData = await Promise.all((data?.comments || []).map(async (comment: Comment) => {
                        const userDocRef = doc(db, 'Usuários', comment.userEmail);
                        const userDocSnap = await getDoc(userDocRef);

                        let userProfileUrl = '';
                        if (userDocSnap.exists()) {
                            const userData = userDocSnap.data();
                            try {
                                userProfileUrl = await getDownloadURL(ref(storage, `Usuários/${comment.userEmail}/FotoPerfil/FotoPerfil`));
                            } catch (error) {
                                userProfileUrl = urlImagemNova || '';
                            }
                            return {
                                ...comment,
                                userName: userData.InfoPessoais.Nome,
                                userDisplayName: userData.InfoPessoais.NomeUsuario,
                                userProfileUrl,
                            };
                        } else {
                            return { ...comment, userProfileUrl };
                        }
                    }));
                    setComments(commentsWithUserData);

                    const imgShowUrl = await getDownloadURL(ref(storage, `Shows/${data?.idshows}/FotoShow/FotoShow`));
                    setUrlImagens(imgShowUrl);

                    const avatarUrl = await getDownloadURL(ref(storage, `Usuários/${data?.usuario}/FotoPerfil/FotoPerfil`));
                    setUrlAvatar(avatarUrl);

                    setImagesLoaded(true);
                } else {
                    console.error('Documento não encontrado!');
                }
            } catch (error) {
                console.error('Erro ao carregar os dados do show:', error);
            }
        };

        fetchShowData();
    }, [db, id, storage, urlImagemNova, hasIncrementedView]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        getDownloadURL(ref(storage, `Usuários/user defalt.png`))
            .then((url) => setUrlImagemNova(url))
            .catch((error) => {
                console.error('Erro ao carregar a imagem padrão:', error.message);
                setUrlImagemNova('');
            });
    }, [storage]);

    const handleCommentSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!newComment.trim()) return;

        if (showData && id) {
            const userEmail = user?.email || 'Anônimo';
            try {
                const comment: Comment = {
                    text: newComment,
                    userEmail: userEmail,
                    timestamp: moment().format('DD/MM/YYYY HH:mm:ss'),
                    likes: 0,
                };

                const docRef = doc(db, 'Shows', id);
                await updateDoc(docRef, {
                    comments: arrayUnion(comment)
                });

                const userDocRef = doc(db, 'Usuários', userEmail);
                const userDocSnap = await getDoc(userDocRef);
                let updatedComment = comment;

                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    updatedComment = {
                        ...comment,
                        userName: userData.InfoPessoais.Nome,
                        userDisplayName: userData.InfoPessoais.NomeUsuario
                    };
                }

                setComments([...comments, updatedComment]);
                setNewComment('');
            } catch (error) {
                console.error("Erro ao obter a URL da foto de perfil:", error);
            }
        }
    };

    if (!showData || !imagesLoaded) {
        return <div>Carregando...</div>;
    }

    const dataEvento = showData.data
        ? moment(showData.data.toDate()).format('dddd, [dia] DD/MM/YYYY [às] HH:mm[h]')
        : 'Data inválida';

    const dataCriacao = showData?.criação ? moment(showData.criação.toDate()).format('DD/MM/YYYY HH:mm') : 'Data não disponível';

    return (
        <div className="container mt-4">
            <p>Criado em {dataCriacao}</p>
            <h2 className="text-center">{showData.titulo}</h2>
            <div className="d-flex align-items-center my-2">
                {isAvatarLoading && <Spinner animation="border" role="status" />}
                <img
                    src={urlAvatar ? urlAvatar : ''}
                    alt="Avatar"
                    onLoad={() => setIsAvatarLoading(false)}
                    style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        marginRight: '15px',
                        display: isAvatarLoading ? 'none' : 'block'
                    }}
                />
                <div>
                    <h5>{showData?.nome || 'Nome não disponível'}</h5>
                    <p>{showData?.nomeusuario || 'Usuário não disponível'}</p>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                }}
            >
                {isImageLoading && <Spinner animation="border" role="status" />}
                <img
                    src={urlImagens}
                    alt="Imagem do evento"
                    onLoad={() => setIsImageLoading(false)}
                    style={{
                        width: '100%',
                        maxWidth: '600px',  // Limite a largura máxima para telas maiores
                        height: 'auto',
                        borderRadius: '10px',
                        display: isImageLoading ? 'none' : 'block',
                        objectFit: 'cover', // Garante que a imagem seja cortada para se ajustar ao container
                    }}
                />
            </div>
            <h5 className='mt-3'><strong className="text-danger">Informações do Evento:</strong></h5>
            <Card>
                <Card.Body>
                    <p><strong>Data: </strong>{dataEvento}</p>
                    <p><strong>Local: </strong>{showData.local}</p>
                    <p><strong>Endereço: </strong>{showData.endereco}</p>
                    <p><strong>Visualizações: </strong>{showData.vizualizações}</p>
                    <p><strong>Artistas: </strong>{showData.artista.join(', ')}</p>
                    <p><strong>Curtidas: </strong>{likeCount}</p>
                    <span style={{ cursor: 'pointer', fontSize: '24px' }} onClick={handleLike}>
                        <i style={{ color: isLiked ? 'red' : 'inherit' }} className={isLiked ? 'fas fa-heart' : 'far fa-heart'}></i>
                    </span>
                </Card.Body>
            </Card>

            <h5 className='mt-3'><strong className="text-danger">Resumo:</strong></h5>
            <Card>
                <Card.Body>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: showData.resumo }} />
                    </div>
                </Card.Body>
            </Card>
            <h5 className='mt-3'><strong className="text-danger">Detalhes:</strong></h5>
            <Card>
                <Card.Body>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: showData.detalhes }} />
                    </div>
                </Card.Body>
            </Card>
            <h4 className='mt-3 text-danger'>Comentários ({comments.length})</h4>
            <Card className='mb-3'>
                <Card.Body>
                    <div>
                        <div className="mt-4">
                            {comments.length > 0 ? (
                                comments.map((comment, index) => (
                                    <div key={index} className="mb-2 d-flex">
                                        <img
                                            src={comment.userProfileUrl || urlImagemNova}
                                            alt="Avatar"
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                borderRadius: '50%',
                                                marginRight: '10px'
                                            }}
                                        />
                                        <div style={{ flex: 1 }}>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <strong>{comment.userName || 'Usuário'}</strong>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>{comment.userDisplayName} - {comment.timestamp}</p>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <p>{comment.text}</p>
                                                <p>Curtidas: {comment.likes}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>Seja o primeiro a comentar!</p>
                            )}
                        </div>
                        {user ? (
                            <>
                                <form onSubmit={handleCommentSubmit} className='mb-5'>
                                    <div className="d-flex justify-content-between align-items-start">
                                        <Avatar src={urlUsuario ? urlUsuario : urlImagemNova} size="l" className="me-3 mb-n2" />
                                        <div style={{ flex: 1 }}>
                                            <h6 className="text-black mb-2">{usuarioNome} ({NomeUsuario})</h6>
                                            <textarea
                                                value={newComment}
                                                onChange={(e) => setNewComment(e.target.value)}
                                                placeholder="Escreva seu comentário..."
                                                rows={3}
                                                className="form-control"
                                            ></textarea>
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary mt-2">Enviar seu comentário</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </>
                        ) : (
                            <p>Faça login para deixar um comentário.</p>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default ShowDetails;
