import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyD_QHlEFLf8B9KsEt548hg3Lyp1k-7PtzM',
  authDomain: 'zuar-189e1.firebaseapp.com',
  projectId: 'zuar-189e1',
  storageBucket: 'zuar-189e1.appspot.com',
  messagingSenderId: '856965635334',
  appId: '1:856965635334:web:89bb5790aae24b298c9287',
  measurementId: 'G-2YVSSL6FC8'
};
// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
