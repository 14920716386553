import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Col, Row, ListGroup, Spinner } from 'react-bootstrap';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { getFirestore, doc, setDoc, getDoc, collection, getDocs, Timestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { User } from 'firebase/auth'

const estados = {
    "Acre": [],
    "Alagoas": [],
    "Amapá": [],
    "Amazonas": [],
    "Bahia": [],
    "Ceará": [],
    "Distrito Federal": [],
    "Espírito Santo": [],
    "Goiás": [],
    "Maranhão": [],
    "Mato Grosso": [],
    "Mato Grosso do Sul": [],
    "Minas Gerais": [],
    "Pará": [],
    "Paraíba": [],
    "Paraná": [],
    "Pernambuco": [],
    "Piauí": [],
    "Rio de Janeiro": [],
    "Rio Grande do Norte": [],
    "Rio Grande do Sul": [],
    "Rondônia": [],
    "Roraima": [],
    "Santa Catarina": [],
    "São Paulo": [],
    "Sergipe": [],
    "Tocantins": []
    // Adicione as cidades relevantes conforme necessário ou mantenha a lista vazia.
};

const LocaisFormulario: React.FC = () => {
    const [nome, setNome] = useState<string>('');
    const [endereco, setEndereco] = useState<string>('');
    const [pais, setPais] = useState<string>('Brasil');
    const [estado, setEstado] = useState<string>('');
    const [cidade, setCidade] = useState<string>('');
    const [regiao, setRegiao] = useState<string>('');
    const [descricao, setDescricao] = useState<string>(''); // Utilizando o editor de texto
    const [locais, setLocais] = useState<string[]>([]);
    const [user, setUser] = useState<User | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [msgTipo, setMsgTipo] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [carregando, setCarregando] = useState<boolean>(false);
    const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
    const db = getFirestore();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
                setMsgTipo('Erro');
                setText('Você precisa estar autenticado para cadastrar locais.');
                setShowModal(true);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchLocais = async () => {
            const locaisSnapshot = await getDocs(collection(db, 'Locais'));
            const locaisList = locaisSnapshot.docs.map(doc => doc.id);
            setLocais(locaisList);
        };

        fetchLocais();
    }, [db]);

    const handlePlaceChanged = () => {
        if (searchBoxRef.current) {
            const places = searchBoxRef.current.getPlaces();
            if (places && places.length > 0) {
                const place = places[0];
                if (place.address_components) {
                    for (const component of place.address_components) {
                        const componentType = component.types[0];

                        switch (componentType) {
                            case 'street_number':
                            case 'route':
                                setEndereco((prev) => `${prev} ${component.long_name}`);
                                break;
                            case 'country':
                                setPais(component.long_name);
                                break;
                            case 'administrative_area_level_1':
                                setEstado(component.long_name);
                                break;
                            case 'locality':
                                setCidade(component.long_name);
                                break;
                        }
                    }
                }
            }
        }
    };

    const handleEstadoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setEstado(e.target.value);
        setCidade(''); // Reset city selection when state changes
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!user) {
            setMsgTipo('Erro');
            setText('Você precisa estar autenticado para cadastrar locais.');
            setShowModal(true);
            return;
        }

        setCarregando(true);

        const nomePadronizado = nome.toLowerCase();  // Padroniza o nome para minúsculas

        const docRef = doc(db, 'Locais', nomePadronizado);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setMsgTipo('Erro');
            setText('Este local já foi cadastrado.');
            setShowModal(true);
            setCarregando(false);
        } else {
            const timestamp = Timestamp.now();  // Usando timestamp do Firestore
            await setDoc(docRef, {
                nome: nomePadronizado,  // Salva o nome padronizado
                endereco,
                pais,
                estado,
                cidade,
                regiao,
                descricao,
                historico: [
                    {
                        email: user.email,
                        dataHora: timestamp,
                        acao: 'Cadastro'
                    }
                ]
            });

            setMsgTipo('sucesso');
            setText('Local cadastrado com sucesso!');
            setShowModal(true);
            setCarregando(false);

            // Limpar os campos do formulário
            setNome('');
            setEndereco('');
            setEstado('');
            setCidade('');
            setRegiao('');
            setDescricao('');
        }
    };

    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' }
            ],
            ['clean']
        ]
    };

    const formats = [
        'header',
        'font',
        'align',
        'bold',
        'italic',
        'underline',
        'list',
        'bullet',
        'link',
        'image'
    ];

    return (
        <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY" libraries={['places']}>
            <div>
                <h3>Locais já cadastrados</h3>
                <ListGroup className="mb-3">
                    {locais.length > 0 ? (
                        locais.map((local, index) => (
                            <ListGroup.Item key={index}>{local}</ListGroup.Item>
                        ))
                    ) : (
                        <ListGroup.Item>Nenhum local cadastrado ainda.</ListGroup.Item>
                    )}
                </ListGroup>
            </div>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formNomeLocal">
                            <Form.Label>Nome do Local</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nome do Local"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formEndereco">
                            <Form.Label>Endereço</Form.Label>
                            <StandaloneSearchBox onLoad={ref => (searchBoxRef.current = ref)} onPlacesChanged={handlePlaceChanged}>
                                <Form.Control
                                    type="text"
                                    placeholder="Endereço"
                                    value={endereco}
                                    onChange={(e) => setEndereco(e.target.value)}
                                    required
                                />
                            </StandaloneSearchBox>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Group controlId="formPais">
                            <Form.Label>País</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="País"
                                value={pais}
                                onChange={(e) => setPais(e.target.value)}
                                required
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formEstado">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select
                                onChange={handleEstadoChange}
                                value={estado}
                                aria-label="Selecione o Estado"
                                required
                            >
                                <option value="">Selecione o Estado</option>
                                {Object.keys(estados).map((estado, index) => (
                                    <option key={index} value={estado}>
                                        {estado}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Group controlId="formCidade">
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Cidade"
                                value={cidade}
                                onChange={(e) => setCidade(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formRegiao">
                            <Form.Label>Região</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Região"
                                value={regiao}
                                onChange={(e) => setRegiao(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formDescricao">
                            <Form.Label>Descrição do Local</Form.Label>
                            <ReactQuill
                                value={descricao}
                                onChange={(value) => setDescricao(value)}
                                modules={modules}
                                formats={formats}
                                placeholder="Digite aqui a descrição do local..."
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" type="submit" disabled={carregando}>
                    {carregando ? <Spinner animation="border" size="sm" /> : 'Cadastrar Local'}
                </Button>
            </Form>

            {/* Modal de Sucesso ou Erro */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center">
                        <i
                            className={`bi ${msgTipo === 'sucesso'
                                    ? 'bi-check-circle-fill'
                                    : 'bi-exclamation-triangle-fill'
                                } text-primary mb-3`}
                            style={{
                                fontSize: '2rem',
                            }}
                        ></i>
                        <h5>Atenção</h5>
                        <p className="text-center">{text}</p>
                        <Button variant="primary" onClick={() => setShowModal(false)}>
                            Confirmar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </LoadScript>
    );
};

export default LocaisFormulario;
