import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import firebase from 'config/firebase';
import { RootState } from 'store';

/** Importações Tema */
import { Stack } from 'react-bootstrap';

/** Moment */
import moment from 'moment';
import 'moment/locale/pt-br';

interface Cronograma {
  DataInicio: Date;
  DataTermino: Date;
}

interface InformaçõesFinanceiras {
  Investimento: number;
}

interface InformaçõesBásicas {
  TituloReduzido: string;
  Status: string;
}

interface Obra {
  id: string;
  InformaçõesBásicas: InformaçõesBásicas;
  Cronograma: Cronograma;
  InformaçõesFinanceiras: InformaçõesFinanceiras;
}

interface HomeData {
  id: string;
  Tipo: string;
  Data: Date | null;
  DescricaoFoto: string;
  Pasta: string;
  TituloReduzido: string;
  Status: string;
  urlImagens: string;
  usuario: string;
}

const Starter = () => {
  //#region Formatar Número
  const numberFormat = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  //#endregion

  const usuarioEmail = useSelector(
    (state: RootState) => state.usuario.usuarioEmail
  );

  //#region Lista de Obras do Usuário
  const [minhasObras, setMinhasObras] = useState<Obra[]>([]);

  useEffect(() => {
    if (usuarioEmail) {
      firebase
        .firestore()
        .collection('Obras')
        .where('EmailView', 'array-contains', usuarioEmail)
        .get()
        .then(async resultado => {
          const obrasFiltradas = resultado.docs
            .map(
              doc =>
                ({
                  id: doc.id,
                  ...doc.data()
                }) as Obra
            ) // Asserção de tipo aqui
            .filter(obra => obra.InformaçõesBásicas.Status === 'Em Andamento'); // Filtrar por Status 'Em Andamento'

          setMinhasObras(obrasFiltradas);
        })
        .catch(error => {
          console.error('Erro ao buscar obras: ', error);
        });
    }
  }, [usuarioEmail]);

  //#endregion

  const [, setInformacoes] = useState<HomeData[]>([]);

  useEffect(() => {
    const unsubscribeList: (() => void)[] = [];
    let infosTemp: HomeData[] = [];

    firebase
      .firestore()
      .collection('Obras')
      .where('EmailView', 'array-contains', usuarioEmail)
      .onSnapshot(obrasSnapshot => {
        obrasSnapshot.docs.forEach(doc => {
          const unsubscribeHome = doc.ref
            .collection('Home')
            .orderBy('Data', 'desc')
            .onSnapshot(homeSnapshot => {
              const homeData = homeSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                  id: doc.id, // Adicionando o id do documento
                  Tipo: data.Tipo,
                  Data: data.Data ? data.Data.toDate() : null,
                  DescricaoFoto: data.DescricaoFoto,
                  Pasta: data.Pasta,
                  TituloReduzido: data.TituloReduzido,
                  Status: data.Status,
                  urlImagens: data.urlImagens,
                  usuario: data.usuário
                };
              });

              infosTemp = [...infosTemp, ...homeData];
              const uniqueInfos = Array.from(
                new Map(infosTemp.map(info => [info.id, info])).values()
              );
              uniqueInfos.sort((a, b) => {
                // Converta datas nulas para uma nova data que representa a data "mais antiga"
                const dateA = a.Data || new Date(0); // 1 de janeiro de 1970
                const dateB = b.Data || new Date(0);

                return dateB.getTime() - dateA.getTime();
              });
              setInformacoes(uniqueInfos);
            });

          unsubscribeList.push(unsubscribeHome); // Adicionar a função de unsubscribe à lista
        });
      });

    return () => {
      // Desinscrever de todos os listeners quando o componente for desmontado
      unsubscribeList.forEach(unsubscribe => unsubscribe());
    };
  }, [usuarioEmail]);

  return (
    <Stack className="content-min-h">
      <div className="container">
        <div className="uk-card-header">
          <h2 className="text-center">Minhas Obras (Em Andamento)</h2>
        </div>
        <div className="row mt-4">
          {minhasObras.map(obra => (
            <div key={obra.id} className="col-md-4 my-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    {obra.InformaçõesBásicas.TituloReduzido}
                  </h5>
                  <p className="card-text">
                    Data de início:{' '}
                    {moment(obra.Cronograma.DataInicio).format('L')}
                  </p>
                  <p className="card-text">
                    Data de término:{' '}
                    {moment(obra.Cronograma.DataTermino).format('L')}
                  </p>
                  <p className="card-text">
                    Investimento:{' '}
                    {numberFormat(obra.InformaçõesFinanceiras.Investimento)}
                  </p>
                  <Link
                    to={'/MinhasObras/Detalhes/' + obra.id}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Mais Detalhes
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Stack>
  );
};

export default Starter;
