import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'config/firebase';
import { TextField } from '@mui/material';
import { Col } from 'react-bootstrap';

/** Importação do Tema */
import { Stack } from 'react-bootstrap';
import NavBarsObrasDetalhes from 'components/navbars/NavBarsObrasDetalhes';

import CustomerOrdersTable from './fiscaisHistorico';
import CustomerRatingsTable from 'components/tables/CustomerRatingsTable';
import CustomerWishlistTable from 'components/tables/CustomerWishlistTable';

/** Moment */
import moment from 'moment';
import 'moment/locale/pt-br';

interface InformacoesBasicas {
  TituloReduzido: string;
  // Adicione outros campos conforme necessário
}

interface Fiscal {
  id: string;
  nome: string;
  Matricula?: string;
  IdFiscal?: string;
  idfiscal?: string;
  Fiscal?: string;
  Situação?: string;
  DataEficácia?: string;
  DataPublicação?: string;
}

interface Fiscalizacao {
  MatrículaFiscal1?: string;
  NomeFiscal1?: string;
  MatrículaFiscal2?: string;
  NomeFiscal2?: string;
  MatrículaFiscal3?: string;
  NomeFiscal3?: string;
}

const FiscaisObra = () => {
  const [, setInformacoesBasicas] = useState<InformacoesBasicas | null>(null);
  const [tituloReduzido, setTituloReduzido] = useState<string>('');

  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .get()
      .then(resultado => {
        const data = resultado.data();
        if (data) {
          const infosBasicas = data.InformaçõesBásicas as InformacoesBasicas; // Assegura que o dado é do tipo InformacoesBasicas
          setInformacoesBasicas(infosBasicas);
          setTituloReduzido(infosBasicas.TituloReduzido);
        }
      });
  }, []);

  const { id } = useParams();
  const [checked] = React.useState(true);

  //#region Fiscalização
  const [viewFisColMat, SetFisColMat] = useState('True');
  function viewModeFisColMat() {
    viewFisColMat == 'True' && SetFisColMat('False');
    viewFisColMat == 'False' && SetFisColMat('True');
  }

  const [viewFisColNome, SetFisColNome] = useState('True');
  function viewModeFisColNome() {
    viewFisColNome == 'True' && SetFisColNome('False');
    viewFisColNome == 'False' && SetFisColNome('True');
  }

  const [viewFisColSit, SetFisColSit] = useState('True');
  function viewModeFisColSit() {
    viewFisColSit == 'True' && SetFisColSit('False');
    viewFisColSit == 'False' && SetFisColSit('True');
  }

  const [viewFisColEfi, SetFisColEfi] = useState('True');
  function viewModeFisColEfi() {
    viewFisColEfi == 'True' && SetFisColEfi('False');
    viewFisColEfi == 'False' && SetFisColEfi('True');
  }

  const [viewFisColPub, SetFisColPub] = useState('True');
  function viewModeFisColPub() {
    viewFisColPub == 'True' && SetFisColPub('False');
    viewFisColPub == 'False' && SetFisColPub('True');
  }

  const [viewFisColEdi, SetFisColEdi] = useState('True');
  function viewModeFisColEdi() {
    viewFisColEdi == 'True' && SetFisColEdi('False');
    viewFisColEdi == 'False' && SetFisColEdi('True');
  }

  const listaFiscais: Fiscal[] = [];
  const [Fiscais, setFiscais] = useState<Fiscal[]>([]);
  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .collection('QuadroFiscalização')
      .orderBy('Fiscal')
      .get()
      .then(async resultado => {
        const fiscaisTemporarios: Fiscal[] = []; // Array temporário para armazenar os fiscais
        resultado.docs.forEach(doc => {
          const data = doc.data();
          // Garanta que cada fiscal tenha um `nome`, mesmo que seja uma string vazia ou um valor padrão
          const fiscal: Fiscal = {
            id: doc.id,
            nome: data.nome || 'Nome não fornecido' // Substitua 'Nome não fornecido' conforme necessário
          };
          fiscaisTemporarios.push(fiscal);
        });
        setFiscais(fiscaisTemporarios); // Atualiza o estado `Fiscais` com os fiscais carregados
      });
  }, []);

  //#region Adicionar Fiscalização
  const [FiscalMatricula, setFiscalMatricula] = useState('');
  const [FiscalNome, setFiscalNome] = useState('');
  const [FiscalSituação, setFiscalSituação] = useState('');
  const [FiscalDataEficácia, setFiscalDataEficácia] = useState('');
  const [FiscalDataPublicação, setFiscalDataPublicação] = useState('');
  function AdicionarFiscalização() {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .collection('QuadroFiscalização')
      .add({
        Matricula: FiscalMatricula,
        Fiscal: FiscalNome,
        Situação: FiscalSituação,
        DataEficácia: FiscalDataEficácia,
        DataPublicação: FiscalDataPublicação
      })
      .then(() => {
        firebase
          .firestore()
          .collection('Obras')
          .doc(id)
          .collection('QuadroFiscalização')
          .orderBy('Fiscal')
          .get()
          .then(async resultado => {
            const fiscaisTemporarios: Fiscal[] = []; // Array temporário para armazenar os fiscais
            resultado.docs.forEach(doc => {
              const data = doc.data();
              // Garanta que cada fiscal tenha um `nome`, mesmo que seja uma string vazia ou um valor padrão
              const fiscal: Fiscal = {
                id: doc.id,
                nome: data.nome || 'Nome não fornecido' // Substitua 'Nome não fornecido' conforme necessário
              };
              fiscaisTemporarios.push(fiscal);
            });
            setFiscais(listaFiscais);
            setFiscalMatricula('');
            setFiscalNome('');
            setFiscalSituação('');
            setFiscalDataEficácia('');
            setFiscalDataPublicação('');
          });
      })
      .catch();
  }
  //#endregion

  //#region Carregar Fiscalização
  const [Fiscalização, setFiscalização] = useState<Fiscalizacao>({});
  const [NomeFiscal1, setNomeFiscal1] = useState('');
  const [MatrículaFiscal1, setMatrículaFiscal1] = useState('');
  const [NomeFiscal2, setNomeFiscal2] = useState('');
  const [MatrículaFiscal2, setMatrículaFiscal2] = useState('');
  const [NomeFiscal3, setNomeFiscal3] = useState('');
  const [MatrículaFiscal3, setMatrículaFiscal3] = useState('');

  useEffect(() => {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .get()
      .then(resultado => {
        const data = resultado.data();
        if (data && data.Fiscalização) {
          // Verifica se data e data.Fiscalização não são undefined
          setFiscalização(data.Fiscalização);
          setNomeFiscal1(data.Fiscalização.NomeFiscal1 || ''); // Usa '' como valor padrão se a propriedade for undefined
          setMatrículaFiscal1(data.Fiscalização.MatrículaFiscal1 || '');
          setNomeFiscal2(data.Fiscalização.NomeFiscal2 || '');
          setMatrículaFiscal2(data.Fiscalização.MatrículaFiscal2 || '');
          setNomeFiscal3(data.Fiscalização.NomeFiscal3 || '');
          setMatrículaFiscal3(data.Fiscalização.MatrículaFiscal3 || '');
        }
      });
  }, []);
  //#endregion

  //#region Editar Fiscalização
  function EditarFiscalização() {
    firebase
      .firestore()
      .collection('Obras')
      .doc(id)
      .update({
        Fiscalização: {
          NomeFiscal1: NomeFiscal1 ? NomeFiscal1 : '',
          MatrículaFiscal1: MatrículaFiscal1 ? MatrículaFiscal1 : '',
          NomeFiscal2: NomeFiscal2 ? NomeFiscal2 : '',
          MatrículaFiscal2: MatrículaFiscal2 ? MatrículaFiscal2 : '',
          NomeFiscal3: NomeFiscal3 ? NomeFiscal3 : '',
          MatrículaFiscal3: MatrículaFiscal3 ? MatrículaFiscal3 : ''
        }
      })
      .then(() => {
        firebase
          .firestore()
          .collection('Obras')
          .doc(id)
          .get()
          .then(resultado => {
            const data = resultado.data();
            if (data && data.Fiscalização) {
              // Verifica se data e data.Fiscalização não são undefined
              setFiscalização(data.Fiscalização);
              setNomeFiscal1(data.Fiscalização.NomeFiscal1 || ''); // Usa '' como valor padrão se a propriedade for undefined
              setMatrículaFiscal1(data.Fiscalização.MatrículaFiscal1 || '');
              setNomeFiscal2(data.Fiscalização.NomeFiscal2 || '');
              setMatrículaFiscal2(data.Fiscalização.MatrículaFiscal2 || '');
              setNomeFiscal3(data.Fiscalização.NomeFiscal3 || '');
              setMatrículaFiscal3(data.Fiscalização.MatrículaFiscal3 || '');
            }
          });
      });
  }
  //#endregion

  return (
    <Stack className="content-min-h">
      <NavBarsObrasDetalhes />
      <div className="container-fluid">
        <section className="col-md-12 mb-3 text-center">
          <h3 className="text-danger mb-3">{tituloReduzido}</h3>
          <h3>Fiscalização</h3>
        </section>
      </div>

      <>
        <Col xs={12} xxl={8}>
          <div className="d-flex flex-column gap-6">
            <div>
              <h3 className="mb-4">
                Orders <span className="text-700 fw-normal">(99)</span>
              </h3>
              <CustomerOrdersTable />
            </div>
            <div>
              <h3 className="mb-4">
                Wishlist <span className="text-700 fw-normal">(43)</span>
              </h3>
              <CustomerWishlistTable />
            </div>
            <div>
              <h3 className="mb-4">
                Ratings & reviews{' '}
                <span className="text-700 fw-normal">(43)</span>
              </h3>
              <CustomerRatingsTable />
            </div>
          </div>
        </Col>

        <div className="uk-card uk-card-default col-md-12">
          <div className="uk-card-header">
            <div className="uk-grid-small uk-flex-middle">
              <div className="uk-width-expand">
                <h3 className="uk-card-title uk-margin-remove-bottom">
                  Fiscalização Atuante
                </h3>
              </div>
            </div>
          </div>
          <div className="uk-card-body">
            <table className="table table-hover text-nowrap">
              <thead className="font-weight-bold">
                <tr>
                  <td>Número</td>
                  <td>Matrícula</td>
                  <td>Nome</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{Fiscalização.MatrículaFiscal1}</td>
                  <td>{Fiscalização.NomeFiscal1}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>{Fiscalização.MatrículaFiscal2}</td>
                  <td>{Fiscalização.NomeFiscal2}</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>{Fiscalização.MatrículaFiscal3}</td>
                  <td>{Fiscalização.NomeFiscal3}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="uk-card-footer">
            <button
              type="button"
              className="btn btn-sm btn-outline-primary float-right"
              data-bs-toggle="modal"
              data-bs-target="#EditarFiscal"
            >
              Editar
            </button>
            <div
              className="modal fade"
              id="EditarFiscal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Editar Fiscal
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-6">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setMatrículaFiscal1(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Nº Matrícula Fical 1"
                          value={MatrículaFiscal1 && MatrículaFiscal1}
                        />
                      </div>
                    </div>
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-12">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setNomeFiscal1(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Nome Fiscal 1"
                          value={NomeFiscal1 ? NomeFiscal1 : ''}
                        />
                      </div>
                    </div>
                    <div className="row col-md-12 border-top my-3"></div>
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-6">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setMatrículaFiscal2(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Nº Matrícula Fical 2"
                          value={MatrículaFiscal2 ? MatrículaFiscal2 : ''}
                        />
                      </div>
                    </div>
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-12">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setNomeFiscal2(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Nome Fiscal 2"
                          value={NomeFiscal2 ? NomeFiscal2 : ''}
                        />
                      </div>
                    </div>
                    <div className="row col-md-12 border-top my-3"></div>
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-6">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setMatrículaFiscal3(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Nº Matrícula Fical 3"
                          value={MatrículaFiscal3 ? MatrículaFiscal3 : ''}
                        />
                      </div>
                    </div>
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-12">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setNomeFiscal3(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Nome Fiscal 3"
                          value={NomeFiscal3 ? NomeFiscal3 : ''}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      data-bs-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      data-bs-dismiss="modal"
                      onClick={EditarFiscalização}
                    >
                      Atualizar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** Histórico Fiscalização */}
        <div className="uk-card uk-card-default col-md-12 mt-3">
          <div className="uk-card-header">
            <div className="uk-grid-small uk-flex-middle">
              <div className="uk-width-expand">
                <h3 className="uk-card-title uk-margin-remove-bottom">
                  Fiscalização Histórico
                </h3>
              </div>
            </div>
          </div>
          <div className="uk-card-body">
            <table className="table table-hover text-nowrap">
              <thead>
                <tr>
                  {viewFisColMat === 'True' && <th>Matrícula</th>}
                  {viewFisColNome === 'True' && <th>Nome</th>}
                  {viewFisColSit === 'True' && <th>Situação</th>}
                  {viewFisColEfi === 'True' && <th>Eficácia</th>}
                  {viewFisColPub === 'True' && <th>Publicação D.O.</th>}
                  {viewFisColEdi === 'False' && <th>Editar</th>}
                </tr>
              </thead>
              <tbody>
                {Fiscais.map((item, index) => (
                  <tr key={index}>
                    {viewFisColMat === 'True' && <td>{item.Matricula}</td>}
                    {viewFisColNome === 'True' && <td>{item.nome}</td>}
                    {viewFisColSit === 'True' && <td>{item.Situação}</td>}
                    {viewFisColEfi === 'True' && (
                      <td>{moment(item.DataEficácia).format('L')}</td>
                    )}
                    {viewFisColPub === 'True' && (
                      <td>{moment(item.DataPublicação).format('L')}</td>
                    )}
                    {viewFisColEdi === 'False' && (
                      <td>
                        <button className="btn btn-sm btn-outline-warning">
                          Editar
                        </button>
                        {/* Aqui você pode adicionar a lógica para editar os fiscais */}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="uk-card-footer">
            <div className="float-left">
              <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    defaultChecked={checked}
                    onClick={viewModeFisColMat}
                  />{' '}
                  Matrícula
                </label>
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    defaultChecked={checked}
                    onClick={viewModeFisColNome}
                  />{' '}
                  Fiscal
                </label>
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    defaultChecked={checked}
                    onClick={viewModeFisColSit}
                  />{' '}
                  Situação
                </label>
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    defaultChecked={checked}
                    onClick={viewModeFisColEfi}
                  />{' '}
                  Eficácia
                </label>
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    defaultChecked={checked}
                    onClick={viewModeFisColPub}
                  />{' '}
                  Publicação D.O.
                </label>
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    onClick={viewModeFisColEdi}
                  />{' '}
                  Editar
                </label>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary float-right"
              data-bs-toggle="modal"
              data-bs-target="#AdicionarFiscal"
            >
              Adicionar
            </button>

            {/** Modal Adicionar Fiscalização */}
            <div
              className="modal fade"
              id="AdicionarFiscal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Adicionar Fiscal
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-6">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setFiscalMatricula(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Nº Matrícula"
                          value={FiscalMatricula && FiscalMatricula}
                        />
                      </div>
                    </div>
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-12">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setFiscalNome(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Nome Fiscal"
                          value={FiscalNome && FiscalNome}
                        />
                      </div>
                    </div>
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-5">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setFiscalSituação(e.target.value)}
                          type="text"
                          className="form-control"
                          label="Situação"
                          value={FiscalSituação && FiscalSituação}
                        />
                      </div>
                    </div>
                    <div className="row col-md-12 mb-5">
                      <div className="col-md-6">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e => setFiscalDataEficácia(e.target.value)}
                          type="date"
                          className="form-control"
                          label="Data Eficácia"
                          value={FiscalDataEficácia && FiscalDataEficácia}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          onChange={e =>
                            setFiscalDataPublicação(e.target.value)
                          }
                          type="date"
                          className="form-control"
                          label="Data Publicação D.O."
                          value={FiscalDataPublicação && FiscalDataPublicação}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      data-bs-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      onClick={AdicionarFiscalização}
                    >
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Stack>
  );
};

export default FiscaisObra;
