import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';

interface DropdownSubMenuProps {
  title: string;
  items: Array<{ title?: string; path?: string; divider?: boolean }>;
  icon: FontAwesomeIconProps['icon'];
}

const DropdownSubMenu: React.FC<DropdownSubMenuProps> = ({
  title,
  items,
  icon
}) => {
  return (
    <Dropdown drop="end" className="dropdown-submenu">
      <Dropdown.Toggle
        as="div"
        className="dropdown-item d-flex align-items-center cursor-pointer"
      >
        <FontAwesomeIcon icon={icon} className="me-2" />
        <span>{title}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map((item, index) =>
          item.divider ? (
            <Dropdown.Divider key={index} />
          ) : (
            <Dropdown.Item
              as={Link}
              to={item.path!}
              key={item.title}
              className="cursor-pointer"
            >
              {item.title}
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSubMenu;
