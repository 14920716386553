import React, { useState } from 'react';
import {
  faBookmark,
  faImages,
  faBook,
  faFile,
  faBars,
  faInfoCircle,
  faUser,
  faDollarSign
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import KanbanInviteModal from 'components/modals/KanbanInviteModal';
import DropdownSubMenu from './DropdownSubMenu'; // Ajuste o caminho de importação conforme necessário

const NavBarObrasDetalhes = () => {
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <div className="kanban-header">
        <Row className="gx-0 justify-content-between justify-content-md-start">
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="text-decoration-none dropdown-caret-none text-1100 fs-8 ps-0 cursor-pointer"
              >
                <FontAwesomeIcon
                  icon={faBars}
                  className="text-500 d-inline-block"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-0">
                <DropdownSubMenu
                  title="Análise da Obra"
                  icon={faInfoCircle}
                  items={[
                    {
                      title: 'Análise Geral',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    },
                    { divider: true },
                    {
                      title: 'Home',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    }
                  ]}
                />
                <DropdownSubMenu
                  title="Info Gerais"
                  icon={faInfoCircle}
                  items={[
                    {
                      title: 'Informações Básicas',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    },
                    {
                      title: 'Localização',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    },
                    { divider: true },
                    {
                      title: 'Informações Básicas',
                      path: `/MinhasObras/Detalhes/${id}/InfoBasicas`
                    },
                    {
                      title: 'Informações Contrato',
                      path: `/MinhasObras/Detalhes/${id}/InfoContrato`
                    },
                    {
                      title: 'Informações Contratado',
                      path: `/MinhasObras/Detalhes/${id}/InfoContrato`
                    }
                  ]}
                />
                <DropdownSubMenu
                  title="Info Contrato"
                  icon={faInfoCircle}
                  items={[
                    {
                      title: 'Informações Contratado',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    },
                    {
                      title: 'Informações Contratante',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    }
                  ]}
                />
                <DropdownSubMenu
                  title="Conograma Físico"
                  icon={faInfoCircle}
                  items={[
                    {
                      title: 'Prazos e Suspensões',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    }
                  ]}
                />
                <DropdownSubMenu
                  title="Info Financeiras"
                  icon={faInfoCircle}
                  items={[
                    {
                      title: 'Cronograma Financeiro',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    },
                    {
                      title: 'Medição',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    },
                    {
                      title: 'Informações Financeiras',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    }
                  ]}
                />
                <DropdownSubMenu
                  title="Usuários e Editores"
                  icon={faInfoCircle}
                  items={[
                    {
                      title: 'Usuários e Editores',
                      path: `/MinhasObras/Detalhes/${id}/Análise`
                    }
                  ]}
                />
                <DropdownSubMenu
                  title="Outra Seção"
                  icon={faUser}
                  items={[
                    {
                      title: 'Informações Contratante',
                      path: `/MinhasObras/Detalhes/${id}/InfoContratante`
                    },
                    {
                      title: 'Informações Financeiras',
                      path: `/MinhasObras/Detalhes/${id}/InfoFinanceiras`
                    },
                    { divider: true },
                    {
                      title: 'Outro Item',
                      path: `/MinhasObras/Detalhes/${id}/OutroItem`
                    }
                  ]}
                />
                <Dropdown.Divider />
                <Dropdown.Item
                  href={`/MinhasObras/Detalhes/${id}/Anotações`}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon icon={faBook} className="me-2" />
                  Anotações
                </Dropdown.Item>
                <Dropdown.Item
                  href={`/MinhasObras/Detalhes/${id}/Documentos`}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon icon={faFile} className="me-2" />
                  Documentos
                </Dropdown.Item>
                <Dropdown.Item
                  href={`/MinhasObras/Detalhes/${id}/Feed`}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon icon={faBookmark} className="me-2" />
                  Feed
                </Dropdown.Item>
                <Dropdown.Item
                  href={`/MinhasObras/Detalhes/${id}/Fiscalização`}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  Fiscalização
                </Dropdown.Item>
                <Dropdown.Item
                  href={`/MinhasObras/Detalhes/${id}/Fotos`}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon icon={faImages} className="me-2" />
                  Fotos
                </Dropdown.Item>
                <Dropdown.Item
                  href={`/MinhasObras/Detalhes/${id}/Feed`}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon icon={faBookmark} className="me-2" />
                  Lista de Tarefas
                </Dropdown.Item>
                <Dropdown.Divider />
                <DropdownSubMenu
                  title="Informações 2"
                  icon={faUser}
                  items={[
                    {
                      title: 'Informações Contratante',
                      path: `/MinhasObras/Detalhes/${id}/InfoContratante`
                    },
                    {
                      title: 'Informações Financeiras',
                      path: `/MinhasObras/Detalhes/${id}/InfoFinanceiras`
                    }
                  ]}
                />
                <Dropdown.Divider />
                <Dropdown.Item
                  href={`/MinhasObras/Detalhes/${id}/CronogramaFinanceiro`}
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon icon={faDollarSign} className="me-2" />
                  Cronograma Financeiro
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3" className="cursor-pointer">
                  Prazos e Suspensões
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3" className="cursor-pointer">
                  Medição
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3" className="cursor-pointer">
                  Lista de Tarefas
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-3" className="cursor-pointer">
                  Usuários
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3" className="cursor-pointer">
                  Editores
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3" className="cursor-pointer">
                  Documentos
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3" className="cursor-pointer">
                  Fotos
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <KanbanInviteModal
        show={openInviteModal}
        handleClose={() => setOpenInviteModal(false)}
      />
    </>
  );
};

export default NavBarObrasDetalhes;
